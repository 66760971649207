export const VALID_GUESSES = [
  'abacavir******',
  'abiraterone***',
  'acetazolamide*',
  'acetylcysteine',
  'aciclovir*****',
  'acitretin*****',
  'aclidinium****',
  'adalimumab****',
  'afatinib******',
  'albendazole***',
  'alfacalcidol**',
  'alfuzosin*****',
  'allopurinol***',
  'alteplase*****',
  'ambrisentan***',
  'amidotrizoate*',
  'amikacin******',
  'amiloride*****',
  'aminophylline*',
  'amiodarone****',
  'amitriptyline*',
  'amlodipine****',
  'amodiaquine***',
  'amoxicillin***',
  'ampicillin****',
  'anastrozole***',
  'antazoline****',
  'tetryzoline***',
  'anidulafungin*',
  'apixaban******',
  'aprepitant****',
  'artemether****',
  'artesunate****',
  'asparaginase**',
  'aspirin*******',
  'atazanavir****',
  'atenolol******',
  'atorvastatin**',
  'atracurium****',
  'atropine******',
  'avibactam*****',
  'azathioprine**',
  'azithromycin**',
  'baricitinib***',
  'beclometasone*',
  'bedaquiline***',
  'bendamustine**',
  'benserazide***',
  'benznidazole**',
  'benzatropine**',
  'betaine*******',
  'betahistine***',
  'betaxolol*****',
  'bethanechol***',
  'bismuth*******',
  'blinatumomab**',
  'betamethasone*',
  'bevacizumab***',
  'bicalutamide**',
  'biperiden*****',
  'bisacodyl*****',
  'bisoprolol****',
  'bleomycin*****',
  'bortezomib****',
  'brigatinib****',
  'brimonidine***',
  'buclizine*****',
  'bromocriptine*',
  'buprenorphine*',
  'busulfan******',
  'budesonide****',
  'bumetanide****',
  'bupivacaine***',
  'bupropion*****',
  'cabergoline***',
  'calamine******',
  'calcipotriol**',
  'calcium*******',
  'calcitriol****',
  'candesartan***',
  'captopril*****',
  'canagliflozin*',
  'capecitabine**',
  'carbachol*****',
  'carmustine****',
  'carbamazepine*',
  'carbetocin****',
  'carbidopa*****',
  'carbimazole***',
  'carboplatin***',
  'carvedilol****',
  'caspofungin***',
  'cefalexin*****',
  'cefazolin*****',
  'cefiderocol***',
  'cefixime******',
  'cefotaxime****',
  'ceftazidime***',
  'ceftriaxone***',
  'cefuroxime****',
  'certolizumab**',
  'celecoxib*****',
  'ceritinib*****',
  'cetirizine****',
  'cetrimide*****',
  'chlorambucil**',
  'chlorhexidine*',
  'chloroquine***',
  'chlorothiazide',
  'chloroxylenol*',
  'chlorpromazine',
  'chlortalidone*',
  'ciclesonide***',
  'ciclosporin***',
  'cimetidine****',
  'ciprofloxacin*',
  'cisplatin*****',
  'citalopram****',
  'clarithromycin',
  'clindamycin***',
  'clofazimine***',
  'clomifene*****',
  'clomipramine**',
  'clonazepam****',
  'clopidogrel***',
  'clotrimazole**',
  'cloxacillin***',
  'clozapine*****',
  'cocaine*******',
  'codeine*******',
  'colchicine****',
  'colecalciferol',
  'colestyramine*',
  'colistin******',
  'cyclizine*****',
  'cyclopentolate',
  'cycloserine***',
  'cytarabine****',
  'cyproheptadine',
  'cyproterone***',
  'dabigatran****',
  'dabrafenib****',
  'dacarbazine***',
  'dacomitinib***',
  'daclatasvir***',
  'dactinomycin**',
  'dalteparin****',
  'dantrolene****',
  'dapagliflozin*',
  'dapsone*******',
  'darunavir*****',
  'dasabuvir*****',
  'dasatinib*****',
  'daunorubicin**',
  'deferasirox***',
  'deferiprone***',
  'deferoxamine**',
  'denosumab*****',
  'dequalinium***',
  'delamanid*****',
  'desmopressin**',
  'dexamethasone*',
  'degarelix*****',
  'diazepam******',
  'diclofenac****',
  'digoxin*******',
  'diloxanide****',
  'diltiazem*****',
  'dimercaprol***',
  'diosmin*******',
  'hesperidin****',
  'diphenoxylate*',
  'dipyridamole**',
  'dobutamine****',
  'donepezil*****',
  'docetaxel*****',
  'docusate******',
  'dolasetron****',
  'dolutegravir**',
  'dopamine******',
  'doxorubicin***',
  'doxycycline***',
  'droperidol****',
  'dutasteride***',
  'dydrogesterone',
  'edoxaban******',
  'efavirenz*****',
  'eltrombopag***',
  'eflornithine**',
  'empagliflozin*',
  'emtricitabine*',
  'enalapril*****',
  'enoxaparin****',
  'entecavir*****',
  'ephedrine*****',
  'epinephrine***',
  'epoprostenol**',
  'eptifibatide**',
  'ergocalciferol',
  'ergometrine***',
  'erlotinib*****',
  'erythromycin**',
  'estradiol*****',
  'escitalopram**',
  'esomeprazole**',
  'etanercept****',
  'ethambutol****',
  'ethanol*******',
  'ethionamide***',
  'ethosuximide**',
  'etoposide*****',
  'etoricoxib****',
  'everolimus****',
  'exemestane****',
  'ezetimibe*****',
  'famotidine****',
  'fenofibrate***',
  'febuxostat****',
  'fentanyl******',
  'fexinidazole**',
  'fexofenadine**',
  'filgrastim****',
  'finasteride***',
  'fingolimod****',
  'flecainide****',
  'fluconazole***',
  'flucytosine***',
  'fludarabine***',
  'flumazenil****',
  'fluocinolone**',
  'flunisolide***',
  'fluorescein***',
  'fluorouracil**',
  'fluoxetine****',
  'flupenthixol**',
  'fluphenazine**',
  'fluticasone***',
  'fluvastatin***',
  'fluvoxamine***',
  'formoterol****',
  'fosfomycin****',
  'furosemide****',
  'gabapentin****',
  'ganciclovir***',
  'gefitinib*****',
  'gemcitabine***',
  'gemfibrozil***',
  'gentamicin****',
  'glecaprevir***',
  'glibenclamide*',
  'gliclazide****',
  'glipizide*****',
  'glucagon******',
  'glutaral******',
  'glycerol******',
  'glycine*******',
  'glycopyrronium',
  'golimumab*****',
  'goserelin*****',
  'granisetron***',
  'griseofulvin**',
  'guaifenesin***',
  'haloperidol***',
  'halothane*****',
  'heparin*******',
  'homatropine***',
  'hydralazine***',
  'hydrocortisone',
  'hydromorphone*',
  'hydroxyzine***',
  'hydroxyurea***',
  'hypromellose**',
  'hyoscine******',
  'ibrutinib*****',
  'ibuprofen*****',
  'ifosfamide****',
  'imatinib******',
  'imipenem******',
  'cilastatin****',
  'indapamide****',
  'infliximab****',
  'insulin*******',
  'iodine********',
  'iohexol*******',
  'ipratropium***',
  'irinotecan****',
  'isoflurane****',
  'isoniazid*****',
  'isoprenaline**',
  'isosorbide****',
  'ivabradine****',
  'ixekizumab****',
  'itraconazole**',
  'ivermectin****',
  'kanamycin*****',
  'kaolin********',
  'ketamine******',
  'ketoprofen****',
  'diosmectite***',
  'lactulose*****',
  'lamivudine****',
  'lamotrigine***',
  'latanoprost***',
  'ledipasvir****',
  'leflunomide***',
  'lenalidomide**',
  'letrozole*****',
  'leuprorelin***',
  'levamisole****',
  'levetiracetam*',
  'levocarnitine*',
  'levocetirizine',
  'levodopa******',
  'levofloxacin**',
  'levonorgestrel',
  'levothyroxine*',
  'lidocaine*****',
  'linagliptin***',
  'linezolid*****',
  'liothyronine**',
  'lisinopril****',
  'lithium*******',
  'lomustine*****',
  'loperamide****',
  'lopinavir*****',
  'loratadine****',
  'lorazepam*****',
  'losartan******',
  'lovastatin****',
  'lumefantrine**',
  'magnesium*****',
  'malathion*****',
  'mannitol******',
  'mebendazole***',
  'mebeverine****',
  'mefloquine****',
  'megestrol*****',
  'melarsoprol***',
  'melphalan*****',
  'mercaptopurine',
  'meropenem*****',
  'mesalazine****',
  'mesna*********',
  'metformin*****',
  'methadone*****',
  'methionine****',
  'methimazole***',
  'methotrexate**',
  'methoxsalen***',
  'methyldopa****',
  'metoclopramide',
  'metoprolol****',
  'metronidazole*',
  'micafungin****',
  'miconazole****',
  'midazolam*****',
  'mirtazapine***',
  'mitomycin*****',
  'mitoxantrone**',
  'moclobemide***',
  'mifepristone**',
  'miltefosine***',
  'misoprostol***',
  'mometasone****',
  'morphine******',
  'moxifloxacin**',
  'mupirocin*****',
  'nadroparin****',
  'naloxone******',
  'nandrolone****',
  'naphazoline***',
  'neomycin******',
  'natamycin*****',
  'neostigmine***',
  'netilmicin****',
  'netupitant****',
  'nevirapine****',
  'niclosamide***',
  'nicotinamide**',
  'nicotine******',
  'nifedipine****',
  'nifurtimox****',
  'nilotinib*****',
  'nitrofurantoin',
  'nivolumab*****',
  'nitrazepam****',
  'norepinephrine',
  'norethisterone',
  'nystatin******',
  'enzalutamide**',
  'octreotide****',
  'olanzapine****',
  'ofloxacin*****',
  'omalizumab****',
  'ombitasvir****',
  'omeprazole****',
  'ondansetron***',
  'oseltamivir***',
  'oxybutynin****',
  'oxaliplatin***',
  'oxycodone*****',
  'oxygen********',
  'oxymetazoline*',
  'oxytocin******',
  'paclitaxel****',
  'paliperidone**',
  'palonosetron**',
  'pamidronate***',
  'pancuronium***',
  'paracetamol***',
  'paritaprevir**',
  'paromomycin***',
  'paroxetine****',
  'pazopanib*****',
  'pemetrexed****',
  'pegaspargase**',
  'pembrolizumab*',
  'penicillamine*',
  'pentamidine***',
  'permethrin****',
  'pethidine*****',
  'phenobarbital*',
  'phenylephrine*',
  'phenytoin*****',
  'physostigmine*',
  'phytomenadione',
  'pibrentasvir**',
  'pilocarpine***',
  'piperacillin**',
  'piperaquine***',
  'piroxicam*****',
  'plazomicin****',
  'posaconazole**',
  'pramipexole***',
  'pralidoxime***',
  'prasugrel*****',
  'pravastatin***',
  'praziquantel**',
  'prazosin******',
  'prednisolone**',
  'prednisone****',
  'primaquine****',
  'probenecid****',
  'procainamide**',
  'procaine******',
  'progesterone**',
  'promethazine**',
  'proparacaine**',
  'propafenone***',
  'procarbazine**',
  'proguanil*****',
  'propofol******',
  'propranolol***',
  'pyrantel******',
  'pyrazinamide**',
  'pyridostigmine',
  'pyridoxine****',
  'pyrimethamine*',
  'pyronaridine**',
  'quetiapine****',
  'quinine*******',
  'raltegravir***',
  'ranibizumab***',
  'ranitidine****',
  'regorafenib***',
  'rasburicase***',
  'retinol*******',
  'ribavirin*****',
  'riboflavin****',
  'rifabutin*****',
  'rifampicin****',
  'rilpivirine***',
  'rifaximin*****',
  'risedronate***',
  'rivastigmine**',
  'ropinirole****',
  'rifapentine***',
  'risperidone***',
  'ritonavir*****',
  'rituximab*****',
  'rivaroxaban***',
  'sacubitril****',
  'valsartan*****',
  'salicylate****',
  'salbutamol****',
  'salmeterol****',
  'senna*********',
  'sennosides****',
  'sertraline*****',
  'simvastatin***',
  'simeticone****',
  'sevelamer*****',
  'siponimod*****',
  'sirolimus*****',
  'somatostatin**',
  'solifenacin***',
  'somatropin****',
  'sorafenib*****',
  'sotalol*******',
  'sofosbuvir****',
  'spectinomycin*',
  'spironolactone',
  'stiripentol***',
  'stibogluconate',
  'streptokinase*',
  'streptomycin**',
  'sulfadiazine**',
  'sulfadoxine***',
  'sulfasalazine*',
  'sumatriptan***',
  'suramin*******',
  'suxamethonium*',
  'tacalcitol****',
  'tacrolimus****',
  'tamoxifen*****',
  'temozolomide**',
  'terazosin*****',
  'tamsulosin****',
  'tazobactam****',
  'telmisartan***',
  'tenofovir*****',
  'terbinafine***',
  'terbutaline***',
  'testosterone**',
  'tetracaine****',
  'thalidomide***',
  'theophylline**',
  'thiamine******',
  'thiopental****',
  'ticagrelor****',
  'ticlopidine***',
  'tolbutamide***',
  'tolterodine***',
  'topiramate****',
  'tramadol******',
  'trametinib****',
  'tretinoin*****',
  'triamcinolone*',
  'triamterene***',
  'timolol*******',
  'tinidazole****',
  'tioguanine****',
  'tiotropium****',
  'tobramycin****',
  'torasemide****',
  'trastuzumab***',
  'trimethoprim**',
  'triptorelin***',
  'tropicamide***',
  'tropisetron***',
  'ulipristal****',
  'umeclidinium**',
  'urea**********',
  'vaborbactam***',
  'valaciclovir**',
  'valganciclovir',
  'vasopressin***',
  'venlafaxine***',
  'vancomycin****',
  'varenicline***',
  'vecuronium****',
  'velpatasvir***',
  'verapamil*****',
  'vilanterol****',
  'vinblastine***',
  'vincristine***',
  'vinorelbine***',
  'voriconazole**',
  'warfarin******',
  'xylometazoline',
  'zidovudine****',
  'abafungin*****',
  'abagovomab****',
  'abamectin*****',
  'abanoquil*****',
  'abarelix******',
  'abciximab*****',
  'abecarnil*****',
  'abediterol****',
  'abexinostat***',
  'abitesartan***',
  'ablukast******',
  'abrineurin****',
  'abunidazole***',
  'acadesine*****',
  'acamprosate***',
  'acaprazine****',
  'acarbose******',
  'acebrochol****',
  'acebutolol****',
  'acecainide****',
  'acecarbromal**',
  'aceclidine****',
  'aceclofenac***',
  'acedapsone****',
  'acediasulfone*',
  'acedoben******',
  'acefluranol***',
  'acefurtiamine*',
  'acefylline****',
  'aceglatone****',
  'aceglutamide**',
  'acemannan*****',
  'acemetacin****',
  'acenocoumarol*',
  'aceperone*****',
  'acepromazine**',
  'aceprometazine*',
  'acequinoline**',
  'acesulfame****',
  'acetaminosalol*',
  'acetarsol*****',
  'acetergamine**',
  'acetiamine****',
  'acetiromate***',
  'acetohexamide*',
  'acetophenazine*',
  'acetorphine***',
  'acetryptine***',
  'acetylleucine*',
  'acetylmethadol*',
  'acevaltrate***',
  'acifran*******',
  'acipimox******',
  'acitazanolast*',
  'acitemate*****',
  'acivicin******',
  'aclantate*****',
  'aclarubicin***',
  'aclatonium****',
  'acodazole*****',
  'aconiazide****',
  'acoxatrine****',
  'acreozast*****',
  'acridorex*****',
  'acrihellin****',
  'acrisorcin****',
  'acrivastine***',
  'acrocinonide**',
  'acronine******',
  'actagardin****',
  'actaplanin****',
  'actarit*******',
  'actinoquinol**',
  'actisomide****',
  'actodigin*****',
  'adafenoxate***',
  'adamexine*****',
  'adapalene*****',
  'adaprolol*****',
  'adargileukin**',
  'adarotene*****',
  'adatanserin***',
  'adefovir******',
  'adelmidrol****',
  'ademetionine**',
  'adenosine*****',
  'aderbasib*****',
  'adibendan*****',
  'adicillin*****',
  'adimolol******',
  'adinazolam****',
  'adiphenine****',
  'adipiodone****',
  'adipiplon*****',
  'aditeren******',
  'aditoprim*****',
  'adosopine*****',
  'adozelesin****',
  'adrafinil*****',
  'adrenalone****',
  'afacifenacine*',
  'afalanine*****',
  'afamelanotide*',
  'afegostat*****',
  'afelimomab****',
  'afimoxifene***',
  'aflibercept***',
  'afloqualone***',
  'afovirsen*****',
  'afurolol******',
  'aganepag******',
  'aganirsen*****',
  'aganodine*****',
  'agatolimod****',
  'aglepristone**',
  'agomelatine***',
  'aklomide******',
  'alacepril*****',
  'alacizumab****',
  'alafosfalin***',
  'alanine*******',
  'alanosine*****',
  'alaproclate***',
  'alatrofloxacin*',
  'alazanine*****',
  'albifylline***',
  'albuglutide***',
  'albuterol*****',
  'albutoin******',
  'alcaftadine***',
  'alclofenac****',
  'alclometasone*',
  'alcloxa*******',
  'alcuronium****',
  'aldesleukin***',
  'aldesulfone***',
  'aldioxa*******',
  'aldosterone***',
  'aleglitazar***',
  'alemtuzumab***',
  'alentemol*****',
  'aleplasinin***',
  'alepride******',
  'alestramustine*',
  'alexidine*****',
  'alfadex*******',
  'alfadolone****',
  'alfaprostol***',
  'alfaxalone****',
  'alfentanil****',
  'alfetamine****',
  'algeldrate****',
  'algestone*****',
  'alglucerase***',
  'alibendol*****',
  'alicaforsen***',
  'aliconazole***',
  'alifedrine****',
  'aliflurane****',
  'alimadol******',
  'alimemazine***',
  'alinastine****',
  'alinidine*****',
  'alipamide*****',
  'alisertib*****',
  'alitretinoin**',
  'alizapride****',
  'alletorphine**',
  'allobarbital**',
  'alloclamide***',
  'allocupreide**',
  'allomethadione*',
  'allylestrenol*',
  'allylprodine**',
  'allylthiourea*',
  'almadrate*****',
  'almagate******',
  'almagodrate***',
  'almasilate****',
  'almecillin****',
  'almestrone****',
  'alminoprofen**',
  'almitrine*****',
  'almokalant****',
  'almotriptan***',
  'almoxatone****',
  'almurtide*****',
  'alnespirone***',
  'alniditan*****',
  'alogliptin****',
  'alonacic******',
  'alonimid******',
  'aloracetam****',
  'alosetron*****',
  'alovudine*****',
  'aloxiprin*****',
  'aloxistatin***',
  'alozafone*****',
  'alpertine*****',
  'alphameprodine*',
  'alphamethadol*',
  'alphaprodine**',
  'alpidem*******',
  'alpiropride***',
  'alprafenone***',
  'alprazolam****',
  'alprenolol****',
  'alprostadil***',
  'alrestatin****',
  'alsactide*****',
  'altanserin****',
  'altapizone****',
  'altebrel******',
  'alteconazole**',
  'altinicline***',
  'altizide******',
  'altoqualine***',
  'altrenogest***',
  'altretamine***',
  'altumomab*****',
  'aluminium*****',
  'alusulf*******',
  'alvelestat****',
  'alverine******',
  'alvespimycin**',
  'alvocidib*****',
  'amadinone*****',
  'amafolone*****',
  'amanozine*****',
  'amantadine****',
  'amantanium****',
  'amantocillin**',
  'ambamustine***',
  'ambasilide****',
  'ambazone******',
  'ambenonium****',
  'ambenoxan*****',
  'ambicromil****',
  'ambomycin*****',
  'ambroxol******',
  'ambruticin****',
  'ambucaine*****',
  'ambucetamide**',
  'ambuside******',
  'amcinafal*****',
  'amcinafide****',
  'amcinonide****',
  'amebucort*****',
  'amedalin******',
  'amediplase****',
  'amelometasone*',
  'ameltolide****',
  'amenamevir****',
  'amesergide****',
  'ametantrone***',
  'amezepine*****',
  'amezinium*****',
  'amfecloral****',
  'amfenac*******',
  'amfepentorex**',
  'amfepramone***',
  'amfetamine****',
  'amfetaminil***',
  'amflutizole***',
  'amfomycin*****',
  'amibegron*****',
  'amicarbalide**',
  'amicibone*****',
  'amicycline****',
  'amidantel*****',
  'amidapsone****',
  'amiflamine****',
  'amifloverine**',
  'amifloxacin***',
  'amifostine****',
  'amiglumide****',
  'amikhelline***',
  'amilomotide***',
  'amindocate****',
  'amineptine****',
  'aminitrozole**',
  'aminoacridine*',
  'aminometradine*',
  'aminopromazine*',
  'aminopterin***',
  'aminoquinol***',
  'aminoquinuride*',
  'aminorex******',
  'aminothiazole*',
  'amiperone*****',
  'amiphenazole**',
  'amipizone*****',
  'amiprilose****',
  'amiquinsin****',
  'amisometradine*',
  'amisulpride***',
  'amiterol******',
  'amitivir******',
  'amitraz*******',
  'amixetrine****',
  'amlexanox*****',
  'amlintide*****',
  'amobarbital***',
  'amocarzine****',
  'amogastrin****',
  'amolanone*****',
  'amonafide*****',
  'amoproxan*****',
  'amopyroquine**',
  'amorolfine****',
  'amoscanate****',
  'amosulalol****',
  'amoxapine*****',
  'amoxecaine****',
  'amoxydramine**',
  'amperozide****',
  'amphenidone***',
  'amphotalide***',
  'ampiroxicam***',
  'amprolium*****',
  'ampyrimine****',
  'ampyzine******',
  'amquinate*****',
  'amrinone******',
  'amrubicin*****',
  'amsacrine*****',
  'amtolmetin****',
  'amuvatinib****',
  'amylmetacresol*',
  'anacetrapib***',
  'anagestone****',
  'anagliptin****',
  'anagrelide****',
  'anakinra******',
  'anamorelin****',
  'anaritide*****',
  'anatumomab****',
  'anaxirone*****',
  'anazocine*****',
  'anazolene*****',
  'ancarolol*****',
  'ancestim******',
  'ancitabine****',
  'ancrod********',
  'andolast******',
  'androstanolone*',
  'anidoxime*****',
  'anilamate*****',
  'anileridine***',
  'anilopam******',
  'anipamil******',
  'aniracetam****',
  'anirolac******',
  'anisacril*****',
  'anisindione***',
  'anisopirol****',
  'anistreplase**',
  'anitrazafen***',
  'anivamersen***',
  'anpirtoline***',
  'anrukinzumab**',
  'ansoxetine****',
  'antafenite****',
  'antazonite****',
  'antelmycin****',
  'anthiolimine**',
  'antienite*****',
  'antrafenine***',
  'antramycin****',
  'apadenoson****',
  'apadoline*****',
  'apafant*******',
  'apalcillin****',
  'apaxifylline**',
  'apicycline****',
  'apilimod******',
  'aplaviroc*****',
  'apovincamine**',
  'apraclonidine*',
  'apramycin*****',
  'apratastat****',
  'apremilast****',
  'apricitabine**',
  'apricoxib*****',
  'aprikalim*****',
  'aprindine*****',
  'aprobarbital**',
  'aprofene******',
  'aprosulate****',
  'aprotinin*****',
  'aptazapine****',
  'aptiganel*****',
  'aptocaine*****',
  'aranidipine***',
  'aranotin******',
  'araprofen*****',
  'arbaclofen****',
  'arbaprostil***',
  'arbekacin*****',
  'arbutamine****',
  'arcitumomab***',
  'arclofenin****',
  'ardeparin*****',
  'arfalasin*****',
  'arfendazam****',
  'argatroban****',
  'argimesna*****',
  'arginine******',
  'argipressin***',
  'argiprestocin*',
  'arhalofenate**',
  'arildone******',
  'aripiprazole**',
  'arnolol*******',
  'arofylline****',
  'aronixil******',
  'arotinolol****',
  'arprinocid****',
  'arpromidine***',
  'arsthinol*****',
  'arteflene*****',
  'artemisinin***',
  'arterolane****',
  'articaine*****',
  'artilide******',
  'arvestin******',
  'aselizumab****',
  'asenapine*****',
  'aseripide*****',
  'asimadoline***',
  'asobamast*****',
  'asocainol*****',
  'aspartame*****',
  'aspartocin****',
  'aspoxicillin**',
  'astemizole****',
  'astromicin****',
  'asunaprevir***',
  'atacicept*****',
  'ataciguat*****',
  'atagabalin****',
  'ataluren******',
  'atamestane****',
  'ataprost******',
  'atevirdine****',
  'atibeprone****',
  'atiglifozin***',
  'atinumab******',
  'atiratecan****',
  'atipamezole***',
  'atiprimod*****',
  'atiprosin*****',
  'atizoram******',
  'atliprofen****',
  'atolide*******',
  'atopaxar******',
  'atorolimumab**',
  'atosiban******',
  'atovaquone****',
  'atreleuton****',
  'atriciguat****',
  'atrimustine***',
  'atrinositol***',
  'atromepine****',
  'auranofin*****',
  'avagacestat***',
  'avasimibe*****',
  'avicatonin****',
  'avilamycin****',
  'aviptadil*****',
  'aviscumine****',
  'avitriptan****',
  'avizafone*****',
  'avobenzone****',
  'avoparcin*****',
  'avorelin******',
  'avosentan*****',
  'avotermin*****',
  'avridine******',
  'axamozide*****',
  'azabon********',
  'azabuperone***',
  'azacitidine***',
  'azaclorzine***',
  'azaconazole***',
  'azacosterol***',
  'azacyclonol***',
  'azaftozine****',
  'azalanstat****',
  'azalomycin****',
  'azaloxan******',
  'azamethonium**',
  'azamulin******',
  'azanator******',
  'azanidazole***',
  'azaperone*****',
  'azaprocin*****',
  'azapropazone**',
  'azaquinzole***',
  'azaribine*****',
  'azaserine*****',
  'azasetron*****',
  'azaspirium****',
  'azastene******',
  'azatadine*****',
  'azatepa*******',
  'azelastine****',
  'azelnidipine**',
  'azepexole*****',
  'azepindole****',
  'azetirelin****',
  'azidamfenicol*',
  'azidocillin***',
  'azimexon******',
  'azimilide*****',
  'azintamide****',
  'azipramine****',
  'azlocillin****',
  'azolimine*****',
  'azosemide*****',
  'azotomycin****',
  'azoximer******',
  'aztreonam*****',
  'azumolene*****',
  'bacampicillin*',
  'bacitracin****',
  'baclofen******',
  'bacmecillinam*',
  'bafetinib*****',
  'bakeprofen****',
  'balamapimod***',
  'balapiravir***',
  'balazipone****',
  'balicatib*****',
  'balofloxacin**',
  'balsalazide***',
  'bamaluzole****',
  'bamaquimast***',
  'bambermycin***',
  'bambuterol****',
  'bamethan******',
  'bamifylline***',
  'baminercept***',
  'bamipine******',
  'bamnidazole***',
  'bapineuzumab**',
  'baquiloprim***',
  'barasertib****',
  'barbexaclone**',
  'barbital******',
  'bardoxolone***',
  'barixibat*****',
  'barmastine****',
  'barnidipine***',
  'barucainide***',
  'barusiban*****',
  'basifungin****',
  'basiliximab***',
  'batanopride***',
  'batebulast****',
  'batelapine****',
  'batilol*******',
  'batimastat****',
  'batoprazine***',
  'batroxobin****',
  'bavisant******',
  'bavituximab***',
  'baxitozine****',
  'bazinaprine***',
  'becampanel****',
  'becantone*****',
  'becaplermin***',
  'beciparcil****',
  'beclamide*****',
  'beclanorsen***',
  'becliconazole*',
  'beclobrate****',
  'beclotiamine**',
  'bectumomab****',
  'bederocin*****',
  'bedoradrine***',
  'befiperide****',
  'befiradol*****',
  'befloxatone***',
  'befunolol*****',
  'befuraline****',
  'begacestat****',
  'bekanamycin***',
  'belaperidone**',
  'belarizine****',
  'belatacept****',
  'belfosdil*****',
  'belinostat****',
  'beloranib*****',
  'beloxamide****',
  'beloxepin*****',
  'bemarinone****',
  'bemegride*****',
  'bemesetron****',
  'bemetizide****',
  'bemitradine***',
  'bemoradan*****',
  'benactyzine***',
  'benafentrine**',
  'benaprizine***',
  'benaxibine****',
  'benazeprilat**',
  'bencianol*****',
  'bencisteine***',
  'benclonidine**',
  'bencyclane****',
  'bendacalol****',
  'bendazac******',
  'bendazol******',
  'benderizine***',
  'benexate******',
  'benfluorex****',
  'benfosformin**',
  'benfotiamine**',
  'benfurodil****',
  'benhepazone***',
  'benidipine****',
  'benmoxin******',
  'benolizime****',
  'benorilate****',
  'benorterone***',
  'benoxafos*****',
  'benoxaprofen**',
  'benpenolisin**',
  'benperidol****',
  'benproperine**',
  'benralizumab**',
  'benrixate*****',
  'bensalan******',
  'bentamapimod**',
  'bentazepam****',
  'bentemazole***',
  'bentiamine****',
  'bentipimine***',
  'bentiromide***',
  'benurestat****',
  'benzalkonium**',
  'benzaprinoxide*',
  'benzarone*****',
  'benzbromarone*',
  'benzestrol****',
  'benzethidine**',
  'benzethonium**',
  'benzetimide***',
  'benzfetamine**',
  'benzilonium***',
  'benzindopyrine*',
  'benziodarone**',
  'benzmalecene**',
  'benzobarbital*',
  'benzocaine****',
  'benzoclidine**',
  'benzoctamine**',
  'benzodepa*****',
  'benzonatate***',
  'benzopyrronium*',
  'benzotript****',
  'benzoxiquine**',
  'benzoxonium***',
  'benzpiperylone*',
  'benzpyrinium**',
  'benzquercin***',
  'benzquinamide*',
  'benzthiazide**',
  'benztropine***',
  'benzydamine***',
  'bepafant******',
  'bepiastine****',
  'bepotastine***',
  'bepridil******',
  'beraprost*****',
  'berefrine*****',
  'berlafenone***',
  'bermoprofen***',
  'bertilimumab**',
  'bertosamil****',
  'berupipam*****',
  'bervastatin***',
  'berythromycin*',
  'besifovir*****',
  'besigomsin****',
  'besilesomab***',
  'besipirdine***',
  'besulpamide***',
  'besunide******',
  'betacarotene**',
  'betadex*******',
  'betameprodine*',
  'betamethadol**',
  'betamicin*****',
  'betamipron****',
  'betanidine****',
  'betaprodine***',
  'betasizofiran*',
  'betazole******',
  'betiatide*****',
  'betoxycaine***',
  'bevantolol****',
  'bevasiranib***',
  'bevirimat*****',
  'bezafibrate***',
  'bezitramide***',
  'bialamicol****',
  'biapenem******',
  'bibapcitide***',
  'bibenzonium***',
  'bicifadine****',
  'biciromab*****',
  'biclodil******',
  'biclofibrate**',
  'biclotymol****',
  'bicozamycin***',
  'bidimazium****',
  'bidisomide****',
  'bietamiverine*',
  'bietaserpine**',
  'bifarcept*****',
  'bifemelane****',
  'bifepramide***',
  'bifeprofen****',
  'bifluranol****',
  'bifonazole****',
  'bimakalim*****',
  'bindarit******',
  'binedaline****',
  'binetrakin****',
  'binfloxacin***',
  'binifibrate***',
  'biniramycin***',
  'binizolast****',
  'binospirone***',
  'bioresmethrin*',
  'biotin********',
  'bipenamol*****',
  'biricodar*****',
  'biriperone****',
  'bisantrene****',
  'bisaramil*****',
  'bisbendazole**',
  'bisbentiamine*',
  'bisegliptin***',
  'bisfenazone***',
  'bisfentidine**',
  'bisnafide*****',
  'bisobrin******',
  'bisorcic******',
  'bisoxatin*****',
  'bithionoloxide*',
  'bitipazone****',
  'bitolterol****',
  'bitoscanate***',
  'bivalirudin***',
  'bivatuzumab***',
  'bixalomer*****',
  'bizelesin*****',
  'blonanserin***',
  'blosozumab****',
  'bluensomycin**',
  'boceprevir****',
  'bofumustine***',
  'bolandiol*****',
  'bolasterone***',
  'bolazine******',
  'boldenone*****',
  'bolmantalate**',
  'bometolol*****',
  'bopindolol****',
  'bornaprine****',
  'bornaprolol***',
  'bornelone*****',
  'bosentan******',
  'bosutinib*****',
  'botiacrine****',
  'boxidine******',
  'brasofensine**',
  'brazergoline**',
  'brecanavir****',
  'brefonalol****',
  'bremazocine***',
  'bremelanotide*',
  'brequinar*****',
  'bretazenil****',
  'bretylium*****',
  'briakinumab***',
  'brifentanil***',
  'brinase*******',
  'brinazarone***',
  'brindoxime****',
  'brinzolamide**',
  'briobacept****',
  'brivanib******',
  'brivaracetam**',
  'brivudine*****',
  'brobactam*****',
  'broclepride***',
  'brocresine****',
  'brocrinat*****',
  'brodalumab****',
  'brodimoprim***',
  'brofaromine***',
  'brofezil******',
  'brofoxine*****',
  'brolaconazole*',
  'brolamfetamine*',
  'bromacrylide**',
  'bromadoline***',
  'bromamide*****',
  'bromazepam****',
  'bromazine*****',
  'bromchlorenone*',
  'bromelains****',
  'bromerguride**',
  'brometenamine*',
  'bromfenac*****',
  'bromhexine****',
  'bromindione***',
  'bromisoval****',
  'bromociclen***',
  'bromofenofos**',
  'bromofos******',
  'bromopride****',
  'bromoxanide***',
  'bromperidol***',
  'bronopol******',
  'broparestrol**',
  'broperamole***',
  'bropirimine***',
  'broquinaldol**',
  'brosotamide***',
  'brosuximide***',
  'brotianide****',
  'brotizolam****',
  'brovanexine***',
  'brovincamine**',
  'broxaldine****',
  'broxaterol****',
  'broxuridine***',
  'broxyquinoline*',
  'bucainide*****',
  'bucetin*******',
  'buciclovir****',
  'bucillamine***',
  'bucindolol****',
  'bucladesine***',
  'buclosamide***',
  'bucolome******',
  'bucricaine****',
  'bucrilate*****',
  'bucromarone***',
  'bucumolol*****',
  'budiodarone***',
  'budipine******',
  'budotitane****',
  'budralazine***',
  'bufenadrine***',
  'bufeniode*****',
  'bufetolol*****',
  'bufexamac*****',
  'bufezolac*****',
  'buflomedil****',
  'bufogenin*****',
  'buformin******',
  'bufrolin******',
  'bufuralol*****',
  'bumadizone****',
  'bumecaine*****',
  'bumepidil*****',
  'bumetrizole***',
  'bunaftine*****',
  'bunamidine****',
  'bunamiodyl****',
  'bunaprolast***',
  'bunazosin*****',
  'bunitrolol****',
  'bunolol*******',
  'buparvaquone**',
  'buphenine*****',
  'bupicomide****',
  'bupranolol****',
  'buquineran****',
  'buquinolate***',
  'buquiterine***',
  'buramate******',
  'burapitant****',
  'burixafor*****',
  'burodiline****',
  'buserelin*****',
  'buspirone*****',
  'butacaine*****',
  'butaclamol****',
  'butadiazamide*',
  'butafosfan****',
  'butalamine****',
  'butalbital****',
  'butamirate****',
  'butamisole****',
  'butamoxane****',
  'butanilicaine*',
  'butanixin*****',
  'butanserin****',
  'butantrone****',
  'butaperazine**',
  'butaprost*****',
  'butaverine****',
  'butaxamine****',
  'butenafine****',
  'buterizine****',
  'butetamate****',
  'buthalital****',
  'butibufen*****',
  'butidrine*****',
  'butikacin*****',
  'butilfenin****',
  'butinazocine**',
  'butinoline****',
  'butirosin*****',
  'butixirate****',
  'butixocort****',
  'butizide******',
  'butobendine***',
  'butoconazole**',
  'butocrolol****',
  'butoctamide***',
  'butofilolol***',
  'butonate******',
  'butopamine****',
  'butopiprine***',
  'butoprozine***',
  'butorphanol***',
  'butoxylate****',
  'butriptyline**',
  'butropium*****',
  'butynamine****',
  'buzepid*******',
  'cabastine*****',
  'cabazitaxel***',
  'cabiotraxetan*',
  'cabozantinib**',
  'cactinomycin**',
  'cadazolid*****',
  'cadexomer*****',
  'cadralazine***',
  'cafaminol*****',
  'cafedrine*****',
  'calaspargase**',
  'calcifediol***',
  'calcipotriene*',
  'calcitonin****',
  'caldiamide****',
  'calteridol****',
  'calusterone***',
  'camazepam*****',
  'cambendazole**',
  'camiglibose***',
  'camiverine****',
  'camobucol*****',
  'camonagrel****',
  'camostat******',
  'camphotamide**',
  'camylofin*****',
  'canakinumab***',
  'canbisol******',
  'candicidin****',
  'candocuronium*',
  'candoxatril***',
  'candoxatrilat*',
  'cannabinol****',
  'canosimibe****',
  'canrenone*****',
  'cantuzumab****',
  'capadenoson***',
  'capeserod*****',
  'capreomycin***',
  'capromab******',
  'capromorelin**',
  'caproxamine***',
  'captodiame****',
  'capuride******',
  'carabersat****',
  'caracemide****',
  'carafiban*****',
  'caramiphen****',
  'carazolol*****',
  'carbadox******',
  'carbaldrate***',
  'carbantel*****',
  'carbaril******',
  'carbarsone****',
  'carbasalate***',
  'carbazeran****',
  'carbazochrome*',
  'carbazocine***',
  'carbenicillin*',
  'carbenoxolone*',
  'carbenzide****',
  'carbetimer****',
  'carbifene*****',
  'carbinoxamine*',
  'carbocisteine*',
  'carbocloral***',
  'carbocromen***',
  'carbofenotion*',
  'carbomycin****',
  'carboprost****',
  'carboquone****',
  'carbromal*****',
  'carbubarb*****',
  'carburazepam**',
  'carbutamide***',
  'carbuterol****',
  'carcainium****',
  'carebastine***',
  'carfecillin***',
  'carfenazine***',
  'carfentanil***',
  'carfilzomib***',
  'carfimate*****',
  'cargutocin****',
  'caricotamide**',
  'carindacillin*',
  'cariporide****',
  'cariprazine***',
  'carisoprodol**',
  'carlumab******',
  'carmantadine**',
  'carmegliptin**',
  'carmellose****',
  'carmetizide***',
  'carmofur******',
  'carmoxirole***',
  'carnidazole***',
  'carnitine*****',
  'carocainide***',
  'carotegrast***',
  'caroverine****',
  'caroxazone****',
  'carperidine***',
  'carperitide***',
  'carperone*****',
  'carpindolol***',
  'carpipramine**',
  'carprazidil***',
  'carprofen*****',
  'carpronium****',
  'carsalam******',
  'carsatrin*****',
  'cartasteine***',
  'cartazolate***',
  'carteolol*****',
  'carubicin*****',
  'carumonam*****',
  'carvotroline**',
  'carzelesin****',
  'carzenide*****',
  'casokefamide**',
  'casopitant****',
  'cathine*******',
  'cathinone*****',
  'catramilast***',
  'catridecacog**',
  'catumaxomab***',
  'cebaracetam***',
  'cedefingol****',
  'cedelizumab***',
  'cediranib*****',
  'cefacetrile***',
  'cefaclor******',
  'cefadroxil****',
  'cefaloglycin**',
  'cefalonium****',
  'cefaloram*****',
  'cefaloridine**',
  'cefalotin*****',
  'cefamandole***',
  'cefaparole****',
  'cefapirin*****',
  'cefatrizine***',
  'cefazaflur****',
  'cefazedone****',
  'cefbuperazone*',
  'cefcanel******',
  'cefcapene*****',
  'cefclidin*****',
  'cefdaloxime***',
  'cefdinir******',
  'cefditoren****',
  'cefedrolor****',
  'cefempidone***',
  'cefepime******',
  'cefetamet*****',
  'cefetecol*****',
  'cefetrizole***',
  'cefivitril****',
  'cefluprenam***',
  'cefmenoxime***',
  'cefmepidium***',
  'cefmetazole***',
  'cefminox******',
  'cefodizime****',
  'cefonicid*****',
  'cefoperazone**',
  'ceforanide****',
  'cefoselis*****',
  'cefotetan*****',
  'cefotiam******',
  'cefoxazole****',
  'cefoxitin*****',
  'cefozopran****',
  'cefpimizole***',
  'cefpiramide***',
  'cefpirome*****',
  'cefpodoxime***',
  'cefprozil*****',
  'cefquinome****',
  'cefradine*****',
  'cefrotil******',
  'cefroxadine***',
  'cefsulodin****',
  'cefsumide*****',
  'cefteram******',
  'ceftezole*****',
  'ceftibuten****',
  'ceftiofur*****',
  'ceftiolene****',
  'ceftioxide****',
  'ceftizoxime***',
  'ceftobiprole**',
  'ceftolozane***',
  'cefuracetime**',
  'cefuzonam*****',
  'celgosivir****',
  'celiprolol****',
  'celivarone****',
  'cellaburate***',
  'cellacefate***',
  'celmoleukin***',
  'celucloral****',
  'cemadotin*****',
  'cenderetide***',
  'cenersen******',
  'cenicriviroc**',
  'cenisertib****',
  'cericlamine***',
  'cerivastatin**',
  'ceronapril****',
  'certoparin****',
  'ceruletide****',
  'cetaben*******',
  'cetalkonium***',
  'cetamolol*****',
  'cetefloxacin**',
  'cetermin******',
  'cethexonium***',
  'cetiedil******',
  'cetocycline***',
  'cetofenicol***',
  'cetohexazine**',
  'cetorelix*****',
  'cetotiamine***',
  'cetoxime******',
  'cetraxate*****',
  'cetrimonium***',
  'cetrorelix****',
  'cetuximab*****',
  'cevimeline****',
  'cevipabulin***',
  'cevoglitazar**',
  'chaulmosulfone*',
  'chiniofon*****',
  'chloracyzine**',
  'chloralodol***',
  'chloralose****',
  'chlorazanil***',
  'chlorazodin***',
  'chlorbetamide*',
  'chlorcyclizine*',
  'chlordimorine*',
  'chlormadinone*',
  'chlormerodrin*',
  'chlormethine**',
  'chlormezanone*',
  'chlormidazole*',
  'chlornaphazine*',
  'chlorobutanol*',
  'chlorocresol**',
  'chloroprocaine*',
  'chloropyramine*',
  'chloropyrilene*',
  'chlorphenamine*',
  'chlorphenesin*',
  'chlorproguanil*',
  'chlorpropamide*',
  'chlorquinaldol*',
  'chlorzoxazone*',
  'cholestyramine*',
  'choline*******',
  'chromocarb****',
  'chymopapain***',
  'chymotrypsin**',
  'ciadox********',
  'ciamexon******',
  'cianergoline**',
  'cianidanol****',
  'cianopramine**',
  'ciapilome*****',
  'cibenzoline***',
  'cicaprost*****',
  'cicarperone***',
  'ciclacillin***',
  'ciclactate****',
  'ciclafrine****',
  'ciclazindol***',
  'cicletanine***',
  'ciclindole****',
  'cicliomenol***',
  'ciclobendazole*',
  'ciclofenazine*',
  'cicloheximide*',
  'ciclonicate***',
  'ciclonium*****',
  'ciclopirox****',
  'ciclopramine**',
  'cicloprofen***',
  'cicloprolol***',
  'ciclosidomine*',
  'ciclotizolam**',
  'ciclotropium**',
  'cicloxolone***',
  'cicortonide***',
  'cideferron****',
  'cidofovir*****',
  'cidoxepin*****',
  'cifostodine***',
  'ciglitazone***',
  'ciheptolane***',
  'ciladopa******',
  'cilansetron***',
  'cilazapril****',
  'cilazaprilat**',
  'cilmostim*****',
  'cilnidipine***',
  'cilobamine****',
  'cilobradine***',
  'cilofungin****',
  'cilostamide***',
  'cilostazol****',
  'ciltoprazine**',
  'cilutazoline**',
  'cimaterol*****',
  'cimemoxin*****',
  'cimepanol*****',
  'cimetropium***',
  'cimoxatone****',
  'cinaciguat****',
  'cinalukast****',
  'cinamolol*****',
  'cinanserin****',
  'cinaproxen****',
  'cinchocaine***',
  'cinchophen****',
  'cinecromen****',
  'cinepaxadil***',
  'cinepazet*****',
  'cinepazide****',
  'cinfenine*****',
  'cinfenoac*****',
  'cinflumide****',
  'cingestol*****',
  'cinitapride***',
  'cinmetacin****',
  'cinnovex******',
  'cinnamaverine*',
  'cinnamedrine**',
  'cinnarizine***',
  'cinnofuradione*',
  'cinnopentazone*',
  'cinoctramide**',
  'cinolazepam***',
  'cinoquidox****',
  'cinoxacin*****',
  'cinoxate******',
  'cinoxolone****',
  'cinoxopazide**',
  'cinperene*****',
  'cinprazole****',
  'cinpropazide**',
  'cinromide*****',
  'cintramide****',
  'cinuperone****',
  'cioteronel****',
  'cipamfylline**',
  'ciprafamide***',
  'ciprazafone***',
  'ciprefadol****',
  'ciprocinonide*',
  'ciprofibrate**',
  'ciprokiren****',
  'cipropride****',
  'ciproquazone**',
  'ciproquinate**',
  'ciprostene****',
  'ciproximide***',
  'ciramadol*****',
  'cirazoline****',
  'cirolemycin***',
  'cisapride*****',
  'cisatracurium*',
  'cisconazole***',
  'cismadinone***',
  'cistinexine***',
  'citatepine****',
  'citatuzumab***',
  'citenamide****',
  'citenazone****',
  'citicoline****',
  'citiolone*****',
  'cixutumumab***',
  'cizolirtine***',
  'cladribine****',
  'clamoxyquine**',
  'clanfenur*****',
  'clanobutin****',
  'clantifen*****',
  'clazolam******',
  'clazolimine***',
  'clazuril******',
  'clebopride****',
  'clefamide*****',
  'clemastine****',
  'clemeprol*****',
  'clemizole*****',
  'clenbuterol***',
  'clenoliximab**',
  'clenpirin*****',
  'clentiazem****',
  'cletoquine****',
  'clevidipine***',
  'clevudine*****',
  'clibucaine****',
  'clidafidine***',
  'clidanac******',
  'clidinium*****',
  'climazolam****',
  'climbazole****',
  'climiqualine**',
  'clinafloxacin*',
  'clinofibrate**',
  'clinolamide***',
  'clinprost*****',
  'clioquinol****',
  'clioxanide****',
  'cliprofen*****',
  'cliropamine***',
  'clobazam******',
  'clobenoside***',
  'clobenzepam***',
  'clobenzorex***',
  'clobenztropine*',
  'clobetasol****',
  'clobetasone***',
  'clobutinol****',
  'clobuzarit****',
  'clocanfamide**',
  'clocapramine**',
  'clociguanil***',
  'clocinizine***',
  'clocortolone**',
  'clocoumarol***',
  'clodacaine****',
  'clodanolene***',
  'clodantoin****',
  'clodazon******',
  'clodoxopone***',
  'clofedanol****',
  'clofenamide***',
  'clofenciclan**',
  'clofenetamine*',
  'clofenotane***',
  'clofenoxyde***',
  'clofenvinfos**',
  'clofeverine***',
  'clofexamide***',
  'clofezone*****',
  'clofibrate****',
  'clofibride****',
  'clofilium*****',
  'clofluperol***',
  'clofoctol*****',
  'cloforex******',
  'clofurac******',
  'clogestone****',
  'cloguanamil***',
  'clomacran*****',
  'clomegestone**',
  'clometacin****',
  'clometerone***',
  'clomethiazole*',
  'clometocillin*',
  'clomifenoxide*',
  'clominorex****',
  'clomocycline**',
  'clomoxir******',
  'clonazoline***',
  'clonidine*****',
  'clonitazene***',
  'clonitrate****',
  'clonixeril****',
  'clonixin******',
  'clopamide*****',
  'clopenthixol**',
  'cloperastine**',
  'cloperidone***',
  'clopidol******',
  'clopimozide***',
  'clopipazan****',
  'clopirac******',
  'cloponone*****',
  'cloprednol****',
  'cloprostenol**',
  'cloprothiazole*',
  'cloquinate****',
  'cloquinozine**',
  'cloracetadol**',
  'cloramfenicol*',
  'cloranolol****',
  'clorazepate***',
  'cloretate*****',
  'clorexolone***',
  'clorgiline****',
  'cloricromen***',
  'cloridarol****',
  'clorindanol***',
  'clorindione***',
  'clormecaine***',
  'clorofene*****',
  'cloroperone***',
  'cloroqualone**',
  'clorotepine***',
  'clorprenaline*',
  'clorsulon*****',
  'clortermine***',
  'closantel*****',
  'closiramine***',
  'clostebol*****',
  'clotiapine****',
  'clotiazepam***',
  'cloticasone***',
  'clotioxone****',
  'clotixamide***',
  'clovoxamine***',
  'cloxacepride**',
  'cloxazolam****',
  'cloxestradiol*',
  'cloximate*****',
  'cloxiquine****',
  'cloxypendyl***',
  'cobamamide****',
  'cobicistat****',
  'cobiprostone**',
  'cocarboxylase*',
  'codactide*****',
  'codoxime******',
  'cofisatin*****',
  'cogazocine****',
  'coleneuramide*',
  'colesevelam***',
  'colestilan****',
  'colestipol****',
  'colestolone***',
  'colextran*****',
  'colfenamate***',
  'colforsin*****',
  'colfosceril***',
  'colimecycline*',
  'colistimethate*',
  'colterol******',
  'conatumumab***',
  'conbercept****',
  'condoliase****',
  'conessine*****',
  'conivaptan****',
  'conorfone*****',
  'corbadrine****',
  'cormetasone***',
  'corticorelin**',
  'corticotropin*',
  'cortisone*****',
  'cortisuzol****',
  'cortivazol****',
  'cortodoxone***',
  'cositecan*****',
  'cotinine******',
  'cotriptyline**',
  'coumafos******',
  'coumamycin****',
  'coumazoline***',
  'coumetarol****',
  'covirasil*****',
  'crenezumab****',
  'crenolanib****',
  'cresotamide***',
  'crilvastatin**',
  'crisnatol*****',
  'crizotinib****',
  'croconazole***',
  'crolibulin****',
  'cromakalim****',
  'cromitrile****',
  'cromoglicate**',
  'cronidipine***',
  'cropropamide**',
  'croscarmellose*',
  'crospovidone**',
  'crotamiton****',
  'crotetamide***',
  'crotoniazide**',
  'crufomate*****',
  'cryofluorane**',
  'cuprimyxin****',
  'cuproxoline***',
  'custirsen*****',
  'cyacetacide***',
  'cyamemazine***',
  'cyanocobalamin*',
  'cyclandelate**',
  'cyclarbamate**',
  'cyclazocine***',
  'cyclazodone***',
  'cyclexanone***',
  'cycliramine***',
  'cyclobarbital*',
  'cyclobutyrol**',
  'cyclofenil****',
  'cycloguanil***',
  'cyclomenol****',
  'cyclopentamine*',
  'cyclopregnol**',
  'cyclopropane**',
  'cyclopyrronium*',
  'cyclothiazide*',
  'cyclovalone***',
  'cycotiamine***',
  'cycrimine*****',
  'cyheptamide***',
  'cyheptropine**',
  'cynarine******',
  'cypenamine****',
  'cyprazepam****',
  'cyprenorphine*',
  'cyprodenate***',
  'cyprolidol****',
  'cyromazine****',
  'cysteine******',
  'dabelotine****',
  'dacemazine****',
  'dacetuzumab***',
  'dacisteine****',
  'daclizumab****',
  'dacopafant****',
  'dacuronium****',
  'dagapamil*****',
  'dalbraminol***',
  'dalcetrapib***',
  'dalcotidine***',
  'daledalin*****',
  'dalfopristin**',
  'dalotuzumab***',
  'daltroban*****',
  'dalvastatin***',
  'dametralast***',
  'damotepine****',
  'danaparoid****',
  'danazol*******',
  'danegaptide***',
  'daniplestim***',
  'daniquidone***',
  'danitracen****',
  'danofloxacin**',
  'danoprevir****',
  'danosteine****',
  'dantron*******',
  'dapabutan*****',
  'dapiclermin***',
  'dapiprazole***',
  'dapitant******',
  'dapoxetine****',
  'daptomycin****',
  'darapladib****',
  'daratumumab***',
  'darenzepine***',
  'darexaban*****',
  'darglitazone**',
  'darifenacin***',
  'darinaparsin**',
  'darodipine****',
  'darotropium***',
  'darsidomine***',
  'datelliptium**',
  'davalintide***',
  'davunetide****',
  'dazadrol******',
  'dazepinil*****',
  'dazidamine****',
  'dazmegrel*****',
  'dazolicine****',
  'dazopride*****',
  'dazoquinast***',
  'dazoxiben*****',
  'deboxamet*****',
  'debrisoquine**',
  'debropol******',
  'decamethonium*',
  'decimemide****',
  'decitabine****',
  'decitropine***',
  'declenperone**',
  'declopramide**',
  'decloxizine***',
  'decominol*****',
  'decoquinate***',
  'dectaflur*****',
  'deditonium****',
  'defibrotide***',
  'deflazacort***',
  'deforolimus***',
  'defosfamide***',
  'dehydroemetine*',
  'delafloxacin**',
  'delanterone***',
  'delapril******',
  'delavirdine***',
  'delequamine***',
  'delergotrile**',
  'delfantrine***',
  'delfaprazine**',
  'delimotecan***',
  'delmadinone***',
  'delmetacin****',
  'delmopinol****',
  'delorazepam***',
  'deloxolone****',
  'delprostenate*',
  'deltibant*****',
  'dembrexine****',
  'demecarium****',
  'demeclocycline*',
  'demecolcine***',
  'demecycline***',
  'demegestone***',
  'demelverine***',
  'demexiptiline*',
  'demiditraz****',
  'democonazole**',
  'demoxepam*****',
  'demoxytocin***',
  'denagliptin***',
  'denatonium****',
  'denaverine****',
  'denbufylline**',
  'denenicokin***',
  'denibulin*****',
  'denileukin****',
  'denipride*****',
  'denopamine****',
  'denotivir*****',
  'denpidazone***',
  'denzimol******',
  'depramine*****',
  'depreotide****',
  'deprodone*****',
  'deprostil*****',
  'deptropine****',
  'deramciclane**',
  'derenofylline*',
  'deriglidole***',
  'derpanicate***',
  'derquantel****',
  'desaspidin****',
  'desciclovir***',
  'descinolone***',
  'deserpidine***',
  'desflurane****',
  'desglugastrin*',
  'desipramine***',
  'desirudin*****',
  'deslanoside***',
  'desloratadine*',
  'deslorelin****',
  'desocriptine**',
  'desogestrel***',
  'desomorphine**',
  'desonide******',
  'desoximetasone*',
  'desoxycortone*',
  'detajmium*****',
  'detanosal*****',
  'deterenol*****',
  'detirelix*****',
  'detomidine****',
  'detorubicin***',
  'detralfate****',
  'detrothyronine*',
  'detumomab*****',
  'devapamil*****',
  'devazepide****',
  'dexamfetamine*',
  'dexamisole****',
  'dexclamol*****',
  'dexecadotril**',
  'dexefaroxan***',
  'dexetimide****',
  'dexetozoline**',
  'dexfosfoserine*',
  'dexibuprofen**',
  'deximafen*****',
  'dexindoprofen*',
  'dexivacaine***',
  'dexketoprofen*',
  'dexlofexidine*',
  'dexloxiglumide*',
  'dexnafenodone*',
  'dexnebivolol**',
  'dexniguldipine*',
  'dexormaplatin*',
  'dexoxadrol****',
  'dexpanthenol**',
  'dexpemedolac**',
  'dexpropranolol*',
  'dexproxibutene*',
  'dexrazoxane***',
  'dexsecoverine*',
  'dexsotalol****',
  'dextilidine***',
  'dextranomer***',
  'dextriferron**',
  'dextrofemine**',
  'dextromoramide*',
  'dextrorphan***',
  'dexverapamil**',
  'dezaguanine***',
  'dezinamide****',
  'dezocine******',
  'diacerein*****',
  'diacetamate***',
  'diacetolol****',
  'diamfenetide**',
  'diamocaine****',
  'diamorphine***',
  'diampromide***',
  'dianicline****',
  'diaplasinin***',
  'diarbarone****',
  'diaveridine***',
  'diaziquone****',
  'diazoxide*****',
  'dibekacin*****',
  'dibemethine***',
  'dibenzepin****',
  'dibromsalan***',
  'dibrospidium**',
  'dibuprol******',
  'dibupyrone****',
  'dibusadol*****',
  'dicarbine*****',
  'dicarfen******',
  'dichlorisone**',
  'dichlorophen**',
  'dichlorvos****',
  'diciferron****',
  'dicirenone****',
  'diclazuril****',
  'diclofenamide*',
  'diclofensine**',
  'diclofurime***',
  'diclometide***',
  'diclonixin****',
  'dicloralurea**',
  'dicloxacillin*',
  'dicolinium****',
  'dicoumarol****',
  'dicresulene***',
  'dicycloverine*',
  'didanosine****',
  'didrovaltrate*',
  'dieldrin******',
  'dienestrol****',
  'dienogest*****',
  'diethadione***',
  'diethazine****',
  'dietifen******',
  'difebarbamate*',
  'difemerine****',
  'difemetorex***',
  'difenamizole**',
  'difencloxazine*',
  'difenidol*****',
  'difenoximide**',
  'difenoxin*****',
  'difetarsone***',
  'difeterol*****',
  'diflorasone***',
  'difloxacin****',
  'difluanazine**',
  'diflucortolone*',
  'diflumidone***',
  'diflunisal****',
  'difluprednate*',
  'diftalone*****',
  'digitoxin*****',
  'dihexyverine**',
  'dihydralazine*',
  'dihydrocodeine*',
  'diisopromine**',
  'dilazep*******',
  'dilevalol*****',
  'dilmapimod****',
  'dilmefone*****',
  'dilopetine****',
  'dimabefylline*',
  'dimadectin****',
  'dimantine*****',
  'dimazole******',
  'dimecamine****',
  'dimecolonium**',
  'dimefadane****',
  'dimefline*****',
  'dimelazine****',
  'dimemorfan****',
  'dimenhydrinate*',
  'dimenoxadol***',
  'dimepheptanol*',
  'dimepranol****',
  'dimepregnen***',
  'dimeprozan****',
  'dimesna*******',
  'dimesone******',
  'dimetacrine***',
  'dimethadione**',
  'dimethazan****',
  'dimethiodal***',
  'dimethisterone*',
  'dimetholizine*',
  'dimethoxanate*',
  'dimetindene***',
  'dimetipirium**',
  'dimetofrine***',
  'dimetotiazine*',
  'dimetridazole*',
  'dimevamide****',
  'diminazene****',
  'dimiracetam***',
  'dimoxaprost***',
  'dimoxyline****',
  'dimpylate*****',
  'dinaciclib****',
  'dinalin*******',
  'dinazafone****',
  'diniprofylline*',
  'dinitolmide***',
  'dinoprost*****',
  'dinoprostone**',
  'dinsed********',
  'diodone*******',
  'dioxadilol****',
  'dioxadrol*****',
  'dioxamate*****',
  'dioxaphetyl***',
  'dioxation*****',
  'dioxethedrin**',
  'dioxifedrine**',
  'dioxybenzone**',
  'diperodon*****',
  'diphemanil****',
  'diphenadione**',
  'diphenan******',
  'diphoxazide***',
  'dipipanone****',
  'dipiproverine*',
  'dipivefrine***',
  'diponium******',
  'diprafenone***',
  'dipraglurant**',
  'diprenorphine*',
  'diprobutine***',
  'diprofene*****',
  'diprophylline*',
  'diproqualone**',
  'diproteverine*',
  'diproxadol****',
  'dipyrithione**',
  'dipyrocetyl***',
  'diquafosol****',
  'dirithromycin*',
  'dirucotide****',
  'disiquonium***',
  'disitertide***',
  'disobutamide**',
  'disofenin*****',
  'disogluside***',
  'disomotide****',
  'disopyramide**',
  'disoxaril*****',
  'distigmine****',
  'disulergine***',
  'disulfamide***',
  'disulfiram****',
  'disuprazole***',
  'ditazole******',
  'ditekiren*****',
  'ditercalinium*',
  'dithiazanine**',
  'dithranol*****',
  'ditiocarb*****',
  'ditiomustine**',
  'ditolamide****',
  'ditophal******',
  'divabuterol***',
  'divaplon******',
  'dixanthogen***',
  'dizatrifone***',
  'dizocilpine***',
  'dobupride*****',
  'docarpamine***',
  'docebenone****',
  'doconazole****',
  'doconexent****',
  'dodeclonium***',
  'dofamium******',
  'dofetilide****',
  'domazoline****',
  'domiodol******',
  'domiphen******',
  'domipizone****',
  'domitroban****',
  'domoprednate**',
  'domoxin*******',
  'domperidone***',
  'donetidine****',
  'dopamantine***',
  'dopexamine****',
  'dopropidil****',
  'doqualast*****',
  'doramectin****',
  'dorastine*****',
  'doreptide*****',
  'doretinel*****',
  'dorlimomab****',
  'dornase*******',
  'dorzolamide***',
  'dosergoside***',
  'dosmalfate****',
  'dosulepin*****',
  'dotarizine****',
  'dotefonium****',
  'dovitinib*****',
  'doxacurium****',
  'doxaminol*****',
  'doxapram******',
  'doxaprost*****',
  'doxazosin*****',
  'doxefazepam***',
  'doxenitoin****',
  'doxepin*******',
  'doxibetasol***',
  'doxifluridine*',
  'doxofylline***',
  'doxpicomine***',
  'doxylamine****',
  'draflazine****',
  'dramedilol****',
  'draquinolol***',
  'drazidox******',
  'dribendazole**',
  'drinabant*****',
  'drinidene*****',
  'drobuline*****',
  'drocinonide***',
  'droclidinium**',
  'drofenine*****',
  'droloxifene***',
  'drometrizole**',
  'dronabinol****',
  'dronedarone***',
  'dropempine****',
  'droprenilamine*',
  'dropropizine**',
  'drospirenone**',
  'drostanolone**',
  'drotaverine***',
  'drotebanol****',
  'drotrecogin***',
  'droxacin******',
  'droxicainide**',
  'droxicam******',
  'droxidopa*****',
  'droxinavir****',
  'droxypropine**',
  'duazomycin****',
  'dulanermin****',
  'dulofibrate***',
  'duloxetine****',
  'dulozafone****',
  'dumorelin*****',
  'duometacin****',
  'duoperone*****',
  'dupracetam****',
  'dutacatib*****',
  'duteplase*****',
  'dutogliptin***',
  'duvoglustat***',
  'dyclonine*****',
  'ebalzotan*****',
  'ebastine******',
  'eberconazole**',
  'ebiratide*****',
  'ebrotidine****',
  'ebselen*******',
  'ecabapide*****',
  'ecabet********',
  'ecadotril*****',
  'ecastolol*****',
  'ecenofloxacin*',
  'ecipramidil***',
  'eclanamine****',
  'eclazolast****',
  'ecogramostim**',
  'ecomustine****',
  'econazole*****',
  'ecothiopate***',
  'ecromeximab***',
  'ectylurea*****',
  'edaravone*****',
  'edatrexate****',
  'edelfosine****',
  'edetol********',
  'edifolone*****',
  'edobacomab****',
  'edodekin******',
  'edogestrone***',
  'edoxudine*****',
  'edrecolomab***',
  'edrophonium***',
  'efaroxan******',
  'efegatran*****',
  'efetozole*****',
  'efletirizine**',
  'efloxate******',
  'eflumast******',
  'efonidipine***',
  'efrotomycin***',
  'efungumab*****',
  'egualen*******',
  'elacridar*****',
  'elacytarabine*',
  'elagolix******',
  'elantrine*****',
  'elanzepine****',
  'elbanizine****',
  'elcatonin*****',
  'eldacimibe****',
  'eldecalcitol**',
  'eledoisin*****',
  'eletriptan****',
  'elfazepam*****',
  'elgodipine****',
  'elinafide*****',
  'elinogrel*****',
  'eliprodil*****',
  'elisartan*****',
  'elisidepsin***',
  'elliptinium***',
  'elmustine*****',
  'elnadipine****',
  'elocalcitol***',
  'elopiprazole**',
  'elpetrigine***',
  'elsamitrucin**',
  'elsibucol*****',
  'elsilimomab***',
  'eltanolone****',
  'eltenac*******',
  'eltoprazine***',
  'elucaine******',
  'elvitegravir**',
  'elziverine****',
  'emakalim******',
  'embramine*****',
  'embusartan****',
  'embutramide***',
  'emedastine****',
  'emepronium****',
  'emfilermin****',
  'emicerfont****',
  'emideltide****',
  'emiglitate****',
  'emilium*******',
  'emitefur******',
  'emoctakin*****',
  'emopamil******',
  'emorfazone****',
  'emricasan*****',
  'emylcamate****',
  'enadoline*****',
  'enalaprilat***',
  'enalkiren*****',
  'enazadrem*****',
  'enbucrilate***',
  'encainide*****',
  'enciprazine***',
  'enclomifene***',
  'encyprate*****',
  'endixaprine***',
  'endomide******',
  'endomycin*****',
  'endralazine***',
  'endrisone*****',
  'enefexine*****',
  'enestebol*****',
  'enflurane*****',
  'englitazone***',
  'eniclobrate***',
  'enilconazole**',
  'enilospirone**',
  'eniluracil****',
  'enisamium*****',
  'enisoprost****',
  'enlimomab*****',
  'enloplatin****',
  'enocitabine***',
  'enofelast*****',
  'enolicam******',
  'enoxacin******',
  'enoxamast*****',
  'enoximone*****',
  'enoxolone*****',
  'enpiprazole***',
  'enpiroline****',
  'enprazepine***',
  'enprofylline**',
  'enpromate*****',
  'enprostil*****',
  'enramycin*****',
  'enrofloxacin**',
  'ensaculin*****',
  'entacapone****',
  'entinostat****',
  'entsufon******',
  'enviomycin****',
  'enviradene****',
  'enviroxime****',
  'epafipase*****',
  'epalrestat****',
  'epanolol******',
  'epelestat*****',
  'eperezolid****',
  'eperisone*****',
  'epervudine****',
  'epetirimod****',
  'epicainide****',
  'epicillin*****',
  'epicriptine***',
  'epiestriol****',
  'epimestrol****',
  'epinastine****',
  'epipropidine**',
  'epirizole*****',
  'epiroprim*****',
  'epirubicin****',
  'epitiostanol**',
  'epitizide*****',
  'epitumomab****',
  'eplerenone****',
  'epoetin*******',
  'epostane******',
  'eprazinone****',
  'epratuzumab***',
  'eprinomectin**',
  'epristeride***',
  'eprobemide****',
  'eprodisate****',
  'eprosartan****',
  'eprotirome****',
  'eprovafen*****',
  'eproxindine***',
  'eprozinol*****',
  'epsiprantel***',
  'eptacog*******',
  'eptaloprost***',
  'eptastigmine**',
  'eptazocine****',
  'eptotermin****',
  'erbulozole****',
  'erdosteine****',
  'ergotamine****',
  'eribaxaban****',
  'eribulin******',
  'ericolol******',
  'eritrityl*****',
  'erizepine*****',
  'erlizumab*****',
  'ertumaxomab***',
  'erocainide****',
  'ersentilide***',
  'ersofermin****',
  'esafloxacin***',
  'esaprazole****',
  'esculamine****',
  'eseridine*****',
  'esflurbiprofen*',
  'esketamine****',
  'esmirtazapine*',
  'esmolol*******',
  'esorubicin****',
  'espatropate***',
  'esproquine****',
  'esreboxetine**',
  'estazolam*****',
  'estramustine**',
  'estrapronicate*',
  'estrazinol****',
  'estriol*******',
  'estrofurate***',
  'estrone*******',
  'esuprone******',
  'etacepride****',
  'etacrynic acid*',
  'etafedrine****',
  'etafenone*****',
  'etamicastat***',
  'etaminile*****',
  'etamiphylline*',
  'etamivan******',
  'etamocycline**',
  'etamsylate****',
  'etanidazole***',
  'etanterol*****',
  'etaqualone****',
  'etaracizumab**',
  'etarotene*****',
  'etasuline*****',
  'etazepine*****',
  'etazolate*****',
  'etebenecid****',
  'eteplirsen****',
  'eterobarb*****',
  'etersalate****',
  'ethacridine***',
  'ethaverine****',
  'ethchlorvynol*',
  'ethenzamide***',
  'ethinamate****',
  'ethisterone***',
  'ethoheptazine*',
  'ethomoxane****',
  'ethotoin******',
  'ethynerone****',
  'ethypicone****',
  'etibendazole**',
  'eticlopride***',
  'eticyclidine**',
  'etidocaine****',
  'etifelmine****',
  'etifenin******',
  'etifoxine*****',
  'etilamfetamine*',
  'etilefrine****',
  'etintidine****',
  'etipirium*****',
  'etiproston****',
  'etiracetam****',
  'etiroxate*****',
  'etisazole*****',
  'etisomicin****',
  'etisulergine**',
  'etizolam******',
  'etocarlide****',
  'etocrilene****',
  'etodolac******',
  'etodroxizine**',
  'etofamide*****',
  'etofenamate***',
  'etofenprox****',
  'etofibrate****',
  'etoformin*****',
  'etofuradine***',
  'etofylline****',
  'etoglucid*****',
  'etolorex******',
  'etolotifen****',
  'etoloxamine***',
  'etomidate*****',
  'etomidoline***',
  'etomoxir******',
  'etonam********',
  'etonitazene***',
  'etonogestrel**',
  'etoperidone***',
  'etoprindole***',
  'etorphine*****',
  'etosalamide***',
  'etoxadrol*****',
  'etoxazene*****',
  'etoxeridine***',
  'etozolin******',
  'etrabamine****',
  'etretinate****',
  'etryptamine***',
  'etymemazine***',
  'etynodiol*****',
  'eucatropine***',
  'eufauserase***',
  'eupatilin*****',
  'euprocin******',
  'evacetrapib***',
  'evandamine****',
  'evatanepag****',
  'exalamide*****',
  'exametazime***',
  'examorelin****',
  'exaprolol*****',
  'exbivirumab***',
  'exepanol******',
  'exifone*******',
  'exiproben*****',
  'ezatiostat****',
  'fabesetron****',
  'fadrozole*****',
  'falecalcitriol*',
  'falintolol****',
  'falipamil*****',
  'famciclovir***',
  'famiraprinium*',
  'famotine******',
  'fampridine****',
  'famprofazone**',
  'fananserin****',
  'fandofloxacin*',
  'fanetizole****',
  'fantofarone***',
  'fantridone****',
  'faralimomab***',
  'farletuzumab**',
  'faropenem*****',
  'fasidotril****',
  'fasiplon******',
  'fasobegron****',
  'fasoracetam***',
  'fasudil*******',
  'favipiravir***',
  'faxeladol*****',
  'fazadinium****',
  'fazarabine****',
  'febantel******',
  'febarbamate***',
  'febuprol******',
  'febuverine****',
  'feclemine*****',
  'feclobuzone***',
  'fedotozine****',
  'fedrilate*****',
  'felbamate*****',
  'felbinac******',
  'felipyrine****',
  'felodipine****',
  'felvizumab****',
  'felypressin***',
  'femoxetine****',
  'fenabutene****',
  'fenacetinol***',
  'fenaclon******',
  'fenadiazole***',
  'fenalamide****',
  'fenalcomine***',
  'fenamifuril***',
  'fenamisal*****',
  'fenamole******',
  'fenaperone****',
  'fenbendazole**',
  'fenbenicillin*',
  'fenbufen******',
  'fenbutrazate**',
  'fencamfamin***',
  'fencarbamide**',
  'fencibutirol**',
  'fenclexonium**',
  'fenclofenac***',
  'fenclofos*****',
  'fenclonine****',
  'fenclorac*****',
  'fendiline*****',
  'fendosal******',
  'feneritrol****',
  'fenestrel*****',
  'fenethazine***',
  'fenetradil****',
  'fenetylline***',
  'fenflumizol***',
  'fenfluramine**',
  'fenfluthrin***',
  'fengabine*****',
  'fenharmane****',
  'fenimide******',
  'feniodium*****',
  'fenipentol****',
  'fenirofibrate*',
  'fenisorex*****',
  'fenleuton*****',
  'fenmetozole***',
  'fenmetramide**',
  'fenobam*******',
  'fenocinol*****',
  'fenoctimine***',
  'fenoldopam****',
  'fenoprofen****',
  'fenoterol*****',
  'fenoverine****',
  'fenoxazoline**',
  'fenoxedil*****',
  'fenozolone****',
  'fenpentadiol**',
  'fenperate*****',
  'fenpipalone***',
  'fenpipramide**',
  'fenpiprane****',
  'fenpiverinium*',
  'fenprinast****',
  'fenproporex***',
  'fenprostalene*',
  'fenquizone****',
  'fenretinide***',
  'fenspiride****',
  'fentiazac*****',
  'fenticlor*****',
  'fenticonazole*',
  'fentonium*****',
  'fenyramidol***',
  'fenyripol*****',
  'fepitrizol****',
  'fepradinol****',
  'feprazone*****',
  'fepromide*****',
  'feprosidnine**',
  'fermagate*****',
  'ferpifosate***',
  'ferrocholinate*',
  'ferropolimaler*',
  'ferroquine****',
  'ferrotrenine**',
  'fertirelin****',
  'ferumoxytol***',
  'fetoxilate****',
  'fexicaine*****',
  'fezakinumab***',
  'fezatione*****',
  'fezolamine****',
  'fiacitabine***',
  'fialuridine***',
  'fibracillin***',
  'fibrin********',
  'fibrinogen****',
  'fibrinolysin**',
  'fidarestat****',
  'fidaxomicin***',
  'figitumumab***',
  'filaminast****',
  'filenadol*****',
  'filibuvir*****',
  'filipin*******',
  'fimasartan****',
  'fipexide******',
  'firategrast***',
  'flamenol******',
  'flavamine*****',
  'flavodilol****',
  'flavoxate*****',
  'flazalone*****',
  'flerobuterol**',
  'fleroxacin****',
  'flesinoxan****',
  'flestolol*****',
  'fletazepam****',
  'flezelastine**',
  'flibanserin***',
  'floctafenine**',
  'flomoxef******',
  'flopristin****',
  'flopropione***',
  'florantyrone**',
  'florbetaben***',
  'flordipine****',
  'floredil******',
  'florfenicol***',
  'florifenine***',
  'flosatidil****',
  'flosequinan***',
  'flosulide*****',
  'flotrenizine**',
  'flovagatran***',
  'floverine*****',
  'floxacrine****',
  'floxuridine***',
  'fluacizine****',
  'flualamide****',
  'fluanisone****',
  'fluazacort****',
  'fluazuron*****',
  'flubanilate***',
  'flubendazole**',
  'flubepride****',
  'flucarbril****',
  'flucetorex****',
  'flucindole****',
  'fluciprazine**',
  'fluclorolone**',
  'flucloxacillin*',
  'flucrilate****',
  'fludalanine***',
  'fludazonium***',
  'fludiazepam***',
  'fludorex******',
  'fludoxopone***',
  'flufenisal****',
  'flufosal******',
  'flufylline****',
  'flugestone****',
  'fluindarol****',
  'fluindione****',
  'flumecinol****',
  'flumedroxone**',
  'flumequine****',
  'flumeridone***',
  'flumetasone***',
  'flumethiazide*',
  'flumetramide**',
  'flumexadol****',
  'flumezapine***',
  'fluminorex****',
  'flumizole*****',
  'flumoxonide***',
  'flunamine*****',
  'flunarizine***',
  'flunidazole***',
  'flunitrazepam*',
  'flunixin******',
  'flunoprost****',
  'flunoxaprofen*',
  'fluocinonide**',
  'fluocortin****',
  'fluocortolone*',
  'fluoresone****',
  'fluorodopa****',
  'fluotracen****',
  'fluparoxan****',
  'flupentixol***',
  'fluperamide***',
  'fluperlapine**',
  'fluperolone***',
  'flupimazine***',
  'flupirtine****',
  'flupranone****',
  'fluprazine****',
  'fluprednidene*',
  'fluprofen*****',
  'fluprofylline*',
  'fluproquazone*',
  'fluprostenol**',
  'fluquazone****',
  'fluradoline***',
  'flurantel*****',
  'flurazepam****',
  'flurbiprofen**',
  'fluretofen****',
  'flurithromycin*',
  'flurocitabine*',
  'flurofamide***',
  'flurotyl******',
  'fluroxene*****',
  'flusalan******',
  'flusoxolol****',
  'fluspiperone**',
  'fluspirilene**',
  'flutamide*****',
  'flutazolam****',
  'flutemazepam**',
  'flutemetamol**',
  'flutiazin*****',
  'flutizenol****',
  'flutomidate***',
  'flutonidine***',
  'flutoprazepam*',
  'flutrimazole**',
  'flutroline****',
  'flutropium****',
  'fluzinamide***',
  'fluzoperine***',
  'fodipir*******',
  'folescutol****',
  'folitixorin***',
  'follitropin***',
  'fomepizole****',
  'fomidacillin**',
  'fominoben*****',
  'fomivirsen****',
  'fomocaine*****',
  'fontolizumab**',
  'fonturacetam**',
  'fopirtoline***',
  'forasartan****',
  'foravirumab***',
  'foretinib*****',
  'forfenimex****',
  'formebolone***',
  'formestane****',
  'formetorex****',
  'forminitrazole*',
  'formocortal***',
  'forodesine****',
  'foropafant****',
  'fosalvudine***',
  'fosaprepitant*',
  'fosarilate****',
  'fosazepam*****',
  'fosbretabulin*',
  'foscarnet*****',
  'fosenazide****',
  'fosfestrol****',
  'fosfluridine**',
  'fosfonet******',
  'fosfosal******',
  'fosinopril****',
  'fosinoprilat**',
  'fosmidomycin**',
  'fosopamine****',
  'fosphenytoin**',
  'fospirate*****',
  'fospropofol***',
  'fosquidone****',
  'fostamatinib**',
  'fostedil******',
  'fostriecin****',
  'fotemustine***',
  'fotretamine***',
  'fozivudine****',
  'frabuprofen***',
  'fradafiban****',
  'framycetin****',
  'frentizole****',
  'fresolimumab**',
  'fronepidil****',
  'frovatriptan**',
  'froxiprost****',
  'ftalofyne*****',
  'ftaxilide*****',
  'ftivazide*****',
  'ftormetazine**',
  'ftorpropazine*',
  'gabexate******',
  'gaboxadol*****',
  'gacyclidine***',
  'gadobutrol****',
  'gadodiamide***',
  'gadopenamide**',
  'gadoteridol***',
  'galamustine***',
  'galantamine***',
  'galdansetron**',
  'gallamine*****',
  'gallium*******',
  'gallopamil****',
  'galocitabine**',
  'galosemide****',
  'galtifenin****',
  'gamfexine*****',
  'gamithromycin*',
  'ganaxolone****',
  'ganefromycin**',
  'ganglefene****',
  'ganirelix*****',
  'gantenerumab**',
  'gapicomine****',
  'garenoxacin***',
  'gatifloxacin**',
  'gavestinel****',
  'gavilimomab***',
  'geclosporin***',
  'gedocarnil****',
  'gefarnate*****',
  'geldanamycin**',
  'gemazocine****',
  'gemcadiol*****',
  'gemeprost*****',
  'gemtuzumab****',
  'gepefrine*****',
  'gepirone******',
  'geroquinol****',
  'gestaclone****',
  'gestadienol***',
  'gestodene*****',
  'gestonorone***',
  'gestrinone****',
  'gevotroline***',
  'giparmen******',
  'giracodazole**',
  'giractide*****',
  'girentuximab**',
  'girisopam*****',
  'gisadenafil***',
  'gitalins******',
  'gitaloxin*****',
  'gitoformate***',
  'givinostat****',
  'glafenine*****',
  'glaspimod*****',
  'glaziovine****',
  'glemanserin***',
  'glembatumumab*',
  'glenvastatin**',
  'gleptoferron**',
  'gliamilide****',
  'glibornuride**',
  'glibutimine***',
  'glicaramide***',
  'glicetanile***',
  'glicondamide**',
  'glidazamide***',
  'gliflumide****',
  'glimepiride***',
  'glipalamide***',
  'gliquidone****',
  'glisamuride***',
  'glisentide****',
  'glisindamide**',
  'glisolamide***',
  'glisoxepide***',
  'gloxazone*****',
  'gloximonam****',
  'glucalox******',
  'glucametacin**',
  'glucarpidase**',
  'glucosamine***',
  'glucosulfamide*',
  'glucosulfone**',
  'glucurolactone*',
  'glucuronamide*',
  'glufosfamide**',
  'glunicate*****',
  'glusoferron***',
  'glutaurine****',
  'glutethimide**',
  'glybuthiazol**',
  'glybuzole*****',
  'glyclopyramide*',
  'glycobiarsol**',
  'glycyclamide**',
  'glyhexamide***',
  'glymidine*****',
  'glyoctamide***',
  'glypinamide***',
  'glyprothiazol*',
  'glysobuzole***',
  'golnerminogene*',
  'golotimod*****',
  'gonadorelin***',
  'goralatide****',
  'gosogliptin***',
  'goxalapladib**',
  'gramicidin****',
  'grepafloxacin*',
  'guabenxan*****',
  'guacetisal****',
  'guafecainol***',
  'guaiactamine**',
  'guaiapate*****',
  'guaietolin****',
  'guaifylline***',
  'guaimesal*****',
  'guaisteine****',
  'guamecycline**',
  'guanabenz*****',
  'guanacline****',
  'guanadrel*****',
  'guanazodine***',
  'guancidine****',
  'guanclofine***',
  'guanethidine**',
  'guanfacine****',
  'guanisoquine**',
  'guanoclor*****',
  'guanoctine****',
  'guanoxabenz***',
  'guanoxan******',
  'guanoxyfen****',
  'guaraprolose**',
  'gusperimus****',
  'hachimycin****',
  'halazepam*****',
  'halazone******',
  'halcinonide***',
  'haletazole****',
  'halocarban****',
  'halocortolone*',
  'halofantrine**',
  'halofenate****',
  'halofuginone**',
  'halometasone**',
  'halonamine****',
  'halopemide****',
  'halopenium****',
  'halopredone***',
  'haloprogin****',
  'haloxazolam***',
  'haloxon*******',
  'hamycin*******',
  'hedaquinium***',
  'heliomycin****',
  'hepronicate***',
  'heptabarb*****',
  'heptaminol****',
  'heptaverine***',
  'heptolamide***',
  'hepzidine*****',
  'hetacillin****',
  'hetaflur******',
  'heteronium****',
  'hexacyprone***',
  'hexadiline****',
  'hexadimethrine*',
  'hexafluronium*',
  'hexamethonium*',
  'hexamidine****',
  'hexapradol****',
  'hexaprofen****',
  'hexapropymate*',
  'hexasonium****',
  'hexedine******',
  'hexestrol*****',
  'hexetidine****',
  'hexobarbital**',
  'hexobendine***',
  'hexocyclium***',
  'hexoprenaline*',
  'hexopyrronium*',
  'hexylcaine****',
  'histapyrrodine*',
  'histidine*****',
  'histrelin*****',
  'homarylamine**',
  'homidium******',
  'homofenazine**',
  'homopipramol**',
  'homosalate****',
  'homprenorphine*',
  'hoquizil******',
  'hyalosidase***',
  'hyaluronidase*',
  'hycanthone****',
  'hydracarbazine*',
  'hydrargaphen**',
  'hydrobentizide*',
  'hydrocodone***',
  'hydrocortamate*',
  'hydromadinone*',
  'hydromorphinol*',
  'hydrotalcite**',
  'hydroxindasate*',
  'hydroxindasol*',
  'hydroxydione**',
  'hymecromone***',
  'ibacitabine***',
  'ibafloxacin***',
  'ibalizumab****',
  'ibazocine*****',
  'ibipinabant***',
  'ibodutant*****',
  'ibopamine*****',
  'ibrotamide****',
  'ibudilast*****',
  'ibufenac******',
  'ibuproxam*****',
  'ibutamoren****',
  'ibuterol******',
  'ibutilide*****',
  'ibuverine*****',
  'icatibant*****',
  'iclaprim******',
  'iclazepam*****',
  'icoduline*****',
  'icometasone***',
  'icopezil******',
  'icosapent*****',
  'icospiramide**',
  'icrocaptide***',
  'idarubicin****',
  'idaverine*****',
  'idazoxan******',
  'idebenone*****',
  'idenast*******',
  'idoxifene*****',
  'idoxuridine***',
  'idralfidine***',
  'idramantone***',
  'idrapril******',
  'idrocilamide**',
  'idropranolol**',
  'ifenprodil****',
  'ifetroban*****',
  'ifoxetine*****',
  'iganidipine***',
  'igmesine******',
  'igovomab******',
  'ilatreotide***',
  'ilepatril*****',
  'ilepcimide****',
  'iliparcil*****',
  'ilmofosine****',
  'ilodecakin****',
  'ilomastat*****',
  'ilonidap******',
  'iloperidone***',
  'iloprost******',
  'imafen********',
  'imagabalin****',
  'imanixil******',
  'imazodan******',
  'imcarbofos****',
  'imciromab*****',
  'imecasermin***',
  'imeglimin*****',
  'imepitoin*****',
  'imetelstat****',
  'imexon********',
  'imiclopazine**',
  'imidafenacin**',
  'imidapril*****',
  'imidaprilat***',
  'imidazole*****',
  'imidocarb*****',
  'imidoline*****',
  'imiglucerase**',
  'imiloxan******',
  'iminophenimide*',
  'imipramine****',
  'imipraminoxide*',
  'imiquimod*****',
  'imirestat*****',
  'imisopasem****',
  'imitrodast****',
  'imolamine*****',
  'imoxiterol****',
  'impacarzine***',
  'impromidine***',
  'improsulfan***',
  'imuracetam****',
  'inakalant*****',
  'inaperisone***',
  'incyclinide***',
  'indacrinone***',
  'indalpine*****',
  'indanazoline**',
  'indanidine****',
  'indanorex*****',
  'indantadol****',
  'indatraline***',
  'indecainide***',
  'indeglitazar**',
  'indeloxazine**',
  'indenolol*****',
  'indinavir*****',
  'indisetron****',
  'indium********',
  'indobufen*****',
  'indocate******',
  'indolapril****',
  'indolidan*****',
  'indometacin***',
  'indomethacin**',
  'indopanolol***',
  'indopine******',
  'indoprofen****',
  'indoramin*****',
  'indorenate****',
  'indoxole******',
  'indriline*****',
  'ingenol*******',
  'inicarone*****',
  'inocoterone***',
  'inogatran*****',
  'inolimomab****',
  'inolitazone***',
  'inotuzumab****',
  'inosine*******',
  'inproquone****',
  'intedanib*****',
  'interferon****',
  'intermedine***',
  'intetumumab***',
  'intoplicine***',
  'intrazole*****',
  'intriptyline**',
  'iobenguane****',
  'iobitridol****',
  'iodamide******',
  'iodecimol*****',
  'iodetryl******',
  'iodixanol*****',
  'iodophthalein*',
  'iodothiouracil*',
  'iofendylate***',
  'iofetamine****',
  'ioflupane*****',
  'iofratol******',
  'ioglucol******',
  'ioglucomide***',
  'ioglunide*****',
  'iolopride*****',
  'iomazenil*****',
  'iomeprol******',
  'iometin*******',
  'iometopane****',
  'iopamidol*****',
  'iopentol******',
  'iopromide*****',
  'iopydol*******',
  'iopydone******',
  'iosarcol******',
  'iosimide******',
  'iosulamide****',
  'iotasul*******',
  'iotriside*****',
  'iotrolan******',
  'ioversol******',
  'ioxilan*******',
  'ipamorelin****',
  'ipazilide*****',
  'ipenoxazone***',
  'ipexidine*****',
  'ipidacrine****',
  'ipilimumab****',
  'ipragliflozin*',
  'ipragratine***',
  'ipramidil*****',
  'iprazochrome**',
  'ipriflavone***',
  'iprindole*****',
  'iproclozide***',
  'iprocrolol****',
  'iproheptine***',
  'iproniazid****',
  'ipronidazole**',
  'iproplatin****',
  'iprotiazem****',
  'iproxamine****',
  'iprozilamine**',
  'ipsalazide****',
  'ipsapirone****',
  'iquindamine***',
  'iralukast*****',
  'iratumumab****',
  'irbesartan****',
  'irindalone****',
  'irloxacin*****',
  'irolapride****',
  'iroplact******',
  'irsogladine***',
  'isaglidole****',
  'isalsteine****',
  'isamfazone****',
  'isamoltan*****',
  'isamoxole*****',
  'isavuconazole*',
  'isaxonine*****',
  'isbogrel******',
  'isbufylline***',
  'isepamicin****',
  'isoaminile****',
  'isobromindione*',
  'isobutamben***',
  'isocarboxazid*',
  'isoconazole***',
  'isocromil*****',
  'isoetarine****',
  'isofezolac****',
  'isoflupredone*',
  'isoleucine****',
  'isomazole*****',
  'isometamidium*',
  'isomethadone**',
  'isometheptene*',
  'isomolpan*****',
  'isonixin******',
  'isoprazone****',
  'isoprednidene*',
  'isoprofen*****',
  'isopropamide**',
  'isopropicillin*',
  'isosulpride***',
  'isothipendyl**',
  'isotiquimide**',
  'isotretinoin**',
  'isoxaprolol***',
  'isoxepac******',
  'isoxicam******',
  'isoxsuprine***',
  'ispronicline**',
  'isradipine****',
  'israpafant****',
  'istaroxime****',
  'istradefylline*',
  'itameline*****',
  'itanoxone*****',
  'itasetron*****',
  'itazigrel*****',
  'itopride******',
  'itrocainide***',
  'itrocinonide**',
  'iturelix******',
  'ivarimod******',
  'ivoqualine****',
  'josamycin*****',
  'kalafungin****',
  'kebuzone******',
  'keliximab*****',
  'keracyanin****',
  'ketanserin****',
  'ketazocine****',
  'ketazolam*****',
  'ketimipramine*',
  'ketobemidone**',
  'ketocaine*****',
  'ketocainol****',
  'ketoconazole**',
  'ketorfanol****',
  'ketorolac*****',
  'ketotifen*****',
  'ketotrexate***',
  'ketoxal*******',
  'khellin*******',
  'khelloside****',
  'kitasamycin***',
  'labetalol*****',
  'labetuzumab***',
  'lacidipine****',
  'lacosamide****',
  'lactalfate****',
  'lactitol******',
  'ladostigil****',
  'laflunimus****',
  'lafutidine****',
  'lagatide******',
  'lagociclovir**',
  'laidlomycin***',
  'lamifiban*****',
  'lamtidine*****',
  'lanatoside****',
  'landiolol*****',
  'lanepitant****',
  'lanoconazole**',
  'lanoteplase***',
  'lanperisone***',
  'lanproston****',
  'lanreotide****',
  'lansoprazole**',
  'laprafylline**',
  'lasalocid*****',
  'lasinavir*****',
  'lasmiditan****',
  'latamoxef*****',
  'latrepirdine**',
  'laudexium*****',
  'lauralkonium**',
  'laurcetium****',
  'laurixamine***',
  'laurocapram***',
  'lauroguadine**',
  'laurolinium***',
  'lauromacrogol*',
  'lavoltidine***',
  'lazabemide****',
  'lebrikizumab**',
  'leconotide****',
  'lecozotan*****',
  'ledazerol*****',
  'ledismase*****',
  'ledoxantrone**',
  'lefetamine****',
  'lefradafiban**',
  'leiopyrrole***',
  'lemalesomab***',
  'lemidosul*****',
  'lemildipine***',
  'leminoprazole*',
  'lenampicillin*',
  'lenapenem*****',
  'lenercept*****',
  'leniquinsin***',
  'lenograstim***',
  'lenperone*****',
  'lepirudin*****',
  'leptacline****',
  'lercanidipine*',
  'lerdelimumab**',
  'lergotrile****',
  'leridistim****',
  'lerisetron****',
  'lersivirine***',
  'lesopitron****',
  'letimide******',
  'letosteine****',
  'letrazuril****',
  'leuciglumer***',
  'leucine*******',
  'leucinocaine**',
  'leucocianidol*',
  'leurubicin****',
  'levallorphan**',
  'levamfetamine*',
  'levcromakalim*',
  'levcycloserine*',
  'levdobutamine*',
  'levemopamil***',
  'levlofexidine*',
  'levobetaxolol*',
  'levobunolol***',
  'levocabastine*',
  'levoglutamide*',
  'levomenol*****',
  'levomethadone*',
  'levomethorphan*',
  'levomoprolol**',
  'levomoramide**',
  'levonantradol*',
  'levoprotiline*',
  'levorin*******',
  'levorphanol***',
  'levosalbutamol*',
  'levosemotiadil*',
  'levosimendan**',
  'levosulpiride*',
  'levoxadrol****',
  'lexacalcitol**',
  'lexatumumab***',
  'lexipafant****',
  'lexithromycin*',
  'lexofenac*****',
  'liarozole*****',
  'liatermin*****',
  'libecillide***',
  'libenzapril***',
  'libivirumab***',
  'licostinel****',
  'lidamidine****',
  'lidanserin****',
  'lidimycin*****',
  'lidofenin*****',
  'lidoflazine***',
  'lifarizine****',
  'lifibrate*****',
  'lifibrol******',
  'lilopristone**',
  'limaprost*****',
  'limazocic*****',
  'linaclotide***',
  'linarotene****',
  'lincomycin****',
  'linetastine***',
  'linifanib*****',
  'linogliride***',
  'linopirdine***',
  'linotroban****',
  'linsidomine***',
  'lintitript****',
  'lintopride****',
  'lintuzumab****',
  'liranaftate***',
  'lirequinil****',
  'lirexapride***',
  'liroldine*****',
  'lisadimate****',
  'lisofylline***',
  'lisuride******',
  'litoxetine****',
  'litracen******',
  'litronesib****',
  'livaraparin***',
  'lividomycin***',
  'lixazinone****',
  'lobaplatin****',
  'lobeline******',
  'lobendazole***',
  'lobenzarit****',
  'lobucavir*****',
  'lobuprofen****',
  'locicortolone*',
  'lodaxaprine***',
  'lodazecar*****',
  'lodelaben*****',
  'lodenosine****',
  'lodinixil*****',
  'lodiperone****',
  'lodoxamide****',
  'lofemizole****',
  'lofendazam****',
  'lofentanil****',
  'lofepramine***',
  'lofexidine****',
  'loflucarban***',
  'lombazole*****',
  'lomefloxacin**',
  'lomerizine****',
  'lometraline***',
  'lometrexol****',
  'lomevactone***',
  'lomifylline***',
  'lomitapide****',
  'lonapalene****',
  'lonaprofen****',
  'lonazolac*****',
  'lonidamine****',
  'lopirazepam***',
  'loprazolam****',
  'loprodiol*****',
  'loracarbef****',
  'lorajmine*****',
  'lorapride*****',
  'lorbamate*****',
  'lorcainide****',
  'lorcinadol****',
  'loreclezole***',
  'lorglumide****',
  'lormetazepam**',
  'lornoxicam****',
  'lorpiprazole**',
  'lortalamine***',
  'lorzafone*****',
  'losigamone****',
  'losindole*****',
  'losmapimod****',
  'losmiprofen***',
  'losoxantrone**',
  'losulazine****',
  'loteprednol***',
  'lotifazole****',
  'lotrafiban****',
  'lotrifen******',
  'lotucaine*****',
  'loviride******',
  'loxanast******',
  'loxapine******',
  'loxiglumide***',
  'loxoprofen****',
  'loxoribine****',
  'lozilurea*****',
  'lubeluzole****',
  'lubiprostone**',
  'lucanthone****',
  'lucartamide***',
  'lucatumumab***',
  'lucimycin*****',
  'lufenuron*****',
  'lufironil*****',
  'lufuradom*****',
  'luliconazole**',
  'lumiliximab***',
  'lumiracoxib***',
  'lunacalcipol**',
  'lupitidine****',
  'luprostiol****',
  'lurasidone****',
  'lurosetron****',
  'lurtotecan****',
  'lusupultide***',
  'lutrelin******',
  'lutropin******',
  'luxabendazole*',
  'lymecycline***',
  'lynestrenol***',
  'lypressin*****',
  'lysergide*****',
  'mabuprofen****',
  'mabuterol*****',
  'macrosalb*****',
  'maduramicin***',
  'mafenide******',
  'mafoprazine***',
  'mafosfamide***',
  'magaldrate****',
  'maitansine****',
  'maletamer*****',
  'malotilate****',
  'mangafodipir**',
  'manidipine****',
  'manitimus*****',
  'mannomustine**',
  'mannosulfan***',
  'manozodil*****',
  'mapatumumab***',
  'mapinastine***',
  'mapracorat****',
  'maprotiline***',
  'maraciclatide*',
  'marbofloxacin*',
  'maridomycin***',
  'mariptiline***',
  'marizomib*****',
  'maropitant****',
  'maroxepin*****',
  'maslimomab****',
  'masoprocol****',
  'matuzumab*****',
  'mavrilimumab**',
  'mazapertine***',
  'mazaticol*****',
  'mazindol******',
  'mazipredone***',
  'mazokalim*****',
  'mebanazine****',
  'mebenoside****',
  'mebezonium****',
  'mebhydrolin***',
  'mebiquine*****',
  'mebolazine****',
  'mebrofenin****',
  'mebutamate****',
  'mebutizide****',
  'mecamylamine**',
  'mecarbinate***',
  'mecasermin****',
  'mecetronium***',
  'meciadanol****',
  'mecillinam****',
  'mecinarone****',
  'meclocycline**',
  'meclofenoxate*',
  'meclonazepam**',
  'mecloqualone**',
  'mecloralurea**',
  'meclorisone***',
  'mecloxamine***',
  'meclozine*****',
  'mecobalamin***',
  'mecrilate*****',
  'mecysteine****',
  'medazepam*****',
  'medazomide****',
  'medetomidine**',
  'medibazine****',
  'medifoxamine**',
  'medorinone****',
  'medorubicin***',
  'medrogestone**',
  'medroxalol****',
  'medrylamine***',
  'medrysone*****',
  'mefeclorazine*',
  'mefenidil*****',
  'mefenidramium*',
  'mefenorex*****',
  'mefeserpine***',
  'mefexamide****',
  'mefruside*****',
  'megalomicin***',
  'meglitinide***',
  'meglucycline**',
  'meglumine*****',
  'meglutol******',
  'meladrazine***',
  'melagatran****',
  'melarsomine***',
  'melarsonyl****',
  'melengestrol**',
  'meletimide****',
  'melinamide****',
  'melitracen****',
  'melizame******',
  'meloxicam*****',
  'melperone*****',
  'melquinast****',
  'meluadrine****',
  'memantine*****',
  'memotine******',
  'menabitan*****',
  'menadiol******',
  'menadione*****',
  'menatetrenone*',
  'menbutone*****',
  'menfegol******',
  'menglytate****',
  'menitrazepam**',
  'menoctone*****',
  'menogaril*****',
  'meobentine****',
  'mepacrine*****',
  'mepartricin***',
  'mepenzolate***',
  'mephenesin****',
  'mephenoxalone*',
  'mephentermine*',
  'mephenytoin***',
  'mepindolol****',
  'mepiprazole***',
  'mepiroxol*****',
  'mepitiostane**',
  'mepivacaine***',
  'mepixanox*****',
  'mepolizumab***',
  'mepramidil****',
  'meprednisone**',
  'meprobamate***',
  'meproscillarin*',
  'meprotixol****',
  'meprylcaine***',
  'meptazinol****',
  'mepyramine****',
  'mequidox******',
  'mequinol******',
  'mequitamium***',
  'mequitazine***',
  'merafloxacin**',
  'meralein******',
  'meralluride***',
  'merbromin*****',
  'mercaptamine**',
  'mercaptomerin*',
  'mercuderamide*',
  'mercumatilin**',
  'mercurobutol**',
  'mergocriptine*',
  'meribendan****',
  'merisoprol****',
  'mersalyl******',
  'mertiatide****',
  'mesabolone****',
  'meseclazone***',
  'mesocarb******',
  'mesoridazine**',
  'mespiperone***',
  'mespirenone***',
  'mestanolone***',
  'mesterolone***',
  'mestranol*****',
  'mesudipine****',
  'mesulergine***',
  'mesulfamide***',
  'mesulfen******',
  'mesuprine*****',
  'mesuximide****',
  'metabromsalan*',
  'metacetamol***',
  'metaclazepam**',
  'metacycline***',
  'metaglycodol**',
  'metahexamide**',
  'metalkonium***',
  'metallibure***',
  'metamelfalan**',
  'metamfazone***',
  'metamfepramone*',
  'metamfetamine*',
  'metamizole****',
  'metampicillin*',
  'metandienone**',
  'metanixin*****',
  'metapramine***',
  'metaraminol***',
  'metaterol*****',
  'metaxalone****',
  'metazamide****',
  'metazide******',
  'metazocine****',
  'metbufen******',
  'metelimumab***',
  'meteneprost***',
  'metenolone****',
  'metergoline***',
  'metergotamine*',
  'metescufylline*',
  'metesculetol**',
  'metesind******',
  'metetoin******',
  'methacholine**',
  'methandriol***',
  'methaniazide**',
  'methaphenilene*',
  'methapyrilene*',
  'methaqualone**',
  'metharbital***',
  'methastyridone*',
  'methazolamide*',
  'methdilazine**',
  'methenamine***',
  'metheptazine**',
  'methestrol****',
  'methiodal*****',
  'methitural****',
  'methixene*****',
  'methocarbamol*',
  'methocidin****',
  'methohexital**',
  'methoprene****',
  'methopromazine*',
  'methoserpidine*',
  'methoxamine***',
  'methoxyflurane*',
  'methylchromone*',
  'methylpentynol*',
  'methyprylon***',
  'methysergide**',
  'metiamide*****',
  'metiapine*****',
  'metibride*****',
  'meticillin****',
  'meticrane*****',
  'metildigoxin**',
  'metindizate***',
  'metioprim*****',
  'metioxate*****',
  'metipirox*****',
  'metipranolol**',
  'metiprenaline*',
  'metirosine****',
  'metisazone****',
  'metitepine****',
  'metixene******',
  'metizoline****',
  'metkefamide***',
  'metochalcone**',
  'metocinium****',
  'metofenazate**',
  'metofoline****',
  'metogest******',
  'metolazone****',
  'metomidate****',
  'metopimazine**',
  'metopon*******',
  'metoquizine***',
  'metoserpate***',
  'metostilenol**',
  'metoxepin*****',
  'metrafazoline*',
  'metralindole**',
  'metrazifone***',
  'metreleptin***',
  'metrenperone**',
  'metribolone***',
  'metrifonate***',
  'metrifudil****',
  'metrizamide***',
  'meturedepa****',
  'metynodiol****',
  'metyrapone****',
  'metyridine****',
  'mevastatin****',
  'mexafylline***',
  'mexazolam*****',
  'mexenone******',
  'mexiletine****',
  'mexiprostil***',
  'mexoprofen****',
  'mexrenoate****',
  'mezacopride***',
  'mezepine******',
  'mezilamine****',
  'mezlocillin***',
  'mianserin*****',
  'mibampator****',
  'mibefradil****',
  'mibolerone****',
  'miboplatin****',
  'micinicate****',
  'micronomicin**',
  'midaflur******',
  'midaglizole***',
  'midamaline****',
  'midazogrel****',
  'midecamycin***',
  'mideplanin****',
  'midesteine****',
  'midodrine*****',
  'mifamurtide***',
  'mifentidine***',
  'mifobate******',
  'migalastat****',
  'miglitol******',
  'mikamycin*****',
  'milacainide***',
  'milacemide****',
  'milameline****',
  'milatuzumab***',
  'milenperone***',
  'milfasartan***',
  'milipertine***',
  'milnacipran***',
  'milodistim****',
  'miloxacin*****',
  'milrinone*****',
  'milverine*****',
  'milveterol****',
  'mimbane*******',
  'minalrestat***',
  'minamestane***',
  'minaprine*****',
  'minaxolone****',
  'mindodilol****',
  'mindoperone***',
  'minepentate***',
  'minocromil****',
  'minocycline***',
  'minolteparin**',
  'minoxidil*****',
  'minretumomab**',
  'mioflazine****',
  'mipimazole****',
  'mipitroban****',
  'mipomersen****',
  'mipragoside***',
  'miproxifene***',
  'mirabegron****',
  'miravirsen****',
  'mirfentanil***',
  'mirimostim****',
  'mirincamycin**',
  'miripirium****',
  'mirisetron****',
  'miristalkonium*',
  'mirococept****',
  'miroprofen****',
  'mirosamicin***',
  'mirostipen****',
  'misonidazole**',
  'mitemcinal****',
  'mitiglinide***',
  'mitindomide***',
  'mitobronitol**',
  'mitocarcin****',
  'mitoclomine***',
  'mitoflaxone***',
  'mitogillin****',
  'mitoguazone***',
  'mitolactol****',
  'mitomalcin****',
  'mitonafide****',
  'mitopodozide**',
  'mitoquidone***',
  'mitoquinone***',
  'mitosper******',
  'mitotane******',
  'mitotenamine**',
  'mitozolomide**',
  'mitumomab*****',
  'mivacurium****',
  'mivazerol*****',
  'mivobulin*****',
  'mixidine******',
  'mizolastine***',
  'mizoribine****',
  'mobecarb******',
  'mobenakin*****',
  'mobenzoxamine*',
  'mocetinostat**',
  'mocimycin*****',
  'mociprazine***',
  'moctamide*****',
  'modafinil*****',
  'modaline******',
  'modecainide***',
  'modipafant****',
  'modithromycin*',
  'moexipril*****',
  'moexiprilat***',
  'mofarotene****',
  'mofebutazone**',
  'mofegiline****',
  'mofezolac*****',
  'mofloverine***',
  'mofoxime******',
  'moguisteine***',
  'molfarnate****',
  'molgramostim**',
  'molinazone****',
  'molindone*****',
  'molracetam****',
  'molsidomine***',
  'monalazone****',
  'monatepil*****',
  'monensin******',
  'monobenzone***',
  'monometacrine*',
  'monoxerutin***',
  'montelukast***',
  'monteplase****',
  'montirelin****',
  'moperone******',
  'mopidamol*****',
  'mopidralazine*',
  'moprolol******',
  'moquizone*****',
  'morantel******',
  'morazone******',
  'morclofone****',
  'morforex******',
  'moricizine****',
  'morinamide****',
  'morniflumate**',
  'morocromen****',
  'moroctocog****',
  'morolimumab***',
  'moroxydine****',
  'morpheridine**',
  'morsuximide***',
  'mosapramine***',
  'mosapride*****',
  'motapizone****',
  'motavizumab***',
  'motesanib*****',
  'motexafin*****',
  'motrazepam****',
  'motretinide***',
  'moveltipril***',
  'moxadolen*****',
  'moxaprindine**',
  'moxastine*****',
  'moxaverine****',
  'moxazocine****',
  'moxestrol*****',
  'moxetumomab***',
  'moxicoumone***',
  'moxidectin****',
  'moxilubant****',
  'moxipraquine**',
  'moxiraprine***',
  'moxisylyte****',
  'moxnidazole***',
  'moxonidine****',
  'mozavaptan****',
  'muplestim*****',
  'murabutide****',
  'muraglitazar**',
  'murocainide***',
  'murodermin****',
  'muromonab*****',
  'muzolimine****',
  'mycophenolate*',
  'myfadol*******',
  'myralact******',
  'myrophine*****',
  'myrtecaine****',
  'nabazenil*****',
  'nabilone******',
  'nabitan*******',
  'naboctate*****',
  'nabumetone****',
  'nacartocin****',
  'nacolomab*****',
  'nadide********',
  'nadifloxacin**',
  'nadolol*******',
  'nadoxolol*****',
  'nafagrel******',
  'nafamostat****',
  'nafarelin*****',
  'nafazatrom****',
  'nafcillin*****',
  'nafenodone****',
  'nafenopin*****',
  'nafetolol*****',
  'nafimidone****',
  'nafiverine****',
  'naflocort*****',
  'nafomine******',
  'nafoxadol*****',
  'nafoxidine****',
  'naftalofos****',
  'naftazone*****',
  'naftidrofuryl*',
  'naftifine*****',
  'naftopidil****',
  'naftoxate*****',
  'naftypramide**',
  'naglivan******',
  'nagrestipen***',
  'nalbuphine****',
  'nalfurafine***',
  'nalmefene*****',
  'nalmexone*****',
  'nalorphine****',
  'naltrexone****',
  'naluzotan*****',
  'naminterol****',
  'namirotene****',
  'namoxyrate****',
  'nanafrocin****',
  'nanofin*******',
  'nanterinone***',
  'nantradol*****',
  'napactadine***',
  'napamezole****',
  'naphthonone***',
  'napirimus*****',
  'napitane******',
  'naprodoxime***',
  'naproxcinod***',
  'naproxen******',
  'naproxol******',
  'napsagatran***',
  'naptumomab****',
  'naranol*******',
  'narasin*******',
  'naratriptan***',
  'nardeterol****',
  'narlaprevir***',
  'naroparcil****',
  'nartograstim**',
  'nasaruplase***',
  'natalizumab***',
  'nateglinide***',
  'nateplase*****',
  'naveglitazar**',
  'navitoclax****',
  'naxagolide****',
  'naxaprostene**',
  'naxifylline***',
  'nealbarbital**',
  'nebacumab*****',
  'nebicapone****',
  'nebidrazine***',
  'nebivolol*****',
  'nebracetam****',
  'nebramycin****',
  'necopidem*****',
  'nedaplatin****',
  'nedocromil****',
  'nefazodone****',
  'nefiracetam***',
  'neflumozide***',
  'nefopam*******',
  'neldazosin****',
  'nelezaprine***',
  'nelfinavir****',
  'nelotanserin**',
  'neltenexine***',
  'nelzarabine***',
  'nemadectin****',
  'nemazoline****',
  'nemifitide****',
  'nemonapride***',
  'nemorubicin***',
  'neocinchophen*',
  'nepadutant****',
  'nepafenac*****',
  'nepaprazole***',
  'nepicastat****',
  'nepinalone****',
  'neptamustine**',
  'nequinate*****',
  'neramexane****',
  'neraminol*****',
  'neratinib*****',
  'nerbacadol****',
  'nerelimomab***',
  'nerisopam*****',
  'nerispirdine**',
  'nesapidil*****',
  'nesbuvir******',
  'nesertide*****',
  'nesosteine****',
  'nestifylline**',
  'neticonazole**',
  'netivudine****',
  'netobimin*****',
  'netoglitazone*',
  'neutramycin***',
  'nexeridine****',
  'nexopamil*****',
  'nialamide*****',
  'niaprazine****',
  'nibroxane*****',
  'nicafenine****',
  'nicainoprol***',
  'nicametate****',
  'nicanartine***',
  'nicaraven*****',
  'nicardipine***',
  'nicergoline***',
  'niceritrol****',
  'niceverine****',
  'niclofolan****',
  'nicoboxil*****',
  'nicoclonate***',
  'nicocodine****',
  'nicocortonide*',
  'nicodicodine**',
  'nicofibrate***',
  'nicofuranose**',
  'nicofurate****',
  'nicogrelate***',
  'nicomol*******',
  'nicomorphine**',
  'nicopholine***',
  'nicorandil****',
  'nicothiazone**',
  'nicotredole***',
  'nicoxamat*****',
  'nictiazem*****',
  'nictindole****',
  'nidroxyzone***',
  'nifekalant****',
  'nifenalol*****',
  'nifenazone****',
  'nifungin******',
  'nifuradene****',
  'nifuraldezone*',
  'nifuralide****',
  'nifuratel*****',
  'nifuratrone***',
  'nifurdazil****',
  'nifurethazone*',
  'nifurfoline***',
  'nifurimide****',
  'nifurizone****',
  'nifurmazole***',
  'nifurmerone***',
  'nifuroquine***',
  'nifuroxazide**',
  'nifuroxime****',
  'nifurpipone***',
  'nifurpirinol**',
  'nifurprazine**',
  'nifurquinazol*',
  'nifursemizone*',
  'nifursol******',
  'nifurthiazole*',
  'nifurtoinol***',
  'nifurvidine***',
  'nifurzide*****',
  'niguldipine***',
  'nihydrazone***',
  'nikethamide***',
  'nileprost*****',
  'nilestriol****',
  'nilprazole****',
  'niludipine****',
  'nilutamide****',
  'nilvadipine***',
  'nimazone******',
  'nimesulide****',
  'nimetazepam***',
  'nimidane******',
  'nimodipine****',
  'nimorazole****',
  'nimotuzumab***',
  'nimustine*****',
  'niometacin****',
  'niperotidine**',
  'nipradilol****',
  'niprofazone***',
  'niravoline****',
  'niridazole****',
  'nisbuterol****',
  'nisobamate****',
  'nisoldipine***',
  'nisoxetine****',
  'nisterime*****',
  'nitarsone*****',
  'nitazoxanide**',
  'nitecapone****',
  'nitisinone****',
  'nitracrine****',
  'nitrafudam****',
  'nitramisole***',
  'nitraquazone**',
  'nitrefazole***',
  'nitrendipine**',
  'nitricholine**',
  'nitroclofene**',
  'nitrocycline**',
  'nitrodan******',
  'nitrofural****',
  'nitromifene***',
  'nitroscanate**',
  'nitroxinil****',
  'nitroxoline***',
  'nivacortol****',
  'nivimedone****',
  'nizatidine****',
  'nizofenone****',
  'noberastine***',
  'nocloprost****',
  'nocodazole****',
  'nofecainide***',
  'nogalamycin***',
  'nolatrexed****',
  'nolinium******',
  'nolpitantium**',
  'nomegestrol***',
  'nomelidine****',
  'nomifensine***',
  'nonabine******',
  'nonacog*******',
  'nonaperone****',
  'nonapyrimine**',
  'nonathymulin**',
  'nonivamide****',
  'nonoxinol*****',
  'noracymethadol*',
  'norboletone***',
  'norbudrine****',
  'norclostebol**',
  'norcodeine****',
  'nordazepam****',
  'nordinone*****',
  'noretynodrel**',
  'noreximide****',
  'norfenefrine**',
  'norfloxacin***',
  'norgesterone**',
  'norgestimate**',
  'norgestomet***',
  'norgestrel****',
  'norgestrienone*',
  'norletimol****',
  'norleusactide*',
  'norlevorphanol*',
  'normethadone**',
  'normorphine***',
  'norpipanone***',
  'nortetrazepam*',
  'nortriptyline*',
  'norvinisterone*',
  'nosantine*****',
  'noscapine*****',
  'nosiheptide***',
  'novobiocin****',
  'noxiptiline***',
  'noxytiolin****',
  'nuclomedone***',
  'nuclotixene***',
  'nufenoxole****',
  'nupafant******',
  'nuvenzepine***',
  'obatoclax*****',
  'oberadilol****',
  'obidoxime*****',
  'oblimersen****',
  'ocaperidone***',
  'ocfentanil****',
  'ociltide******',
  'ocinaplon*****',
  'ocrase********',
  'ocrelizumab***',
  'ocrilate******',
  'ocriplasmin***',
  'octabenzone***',
  'octacaine*****',
  'octafonium****',
  'octamoxin*****',
  'octamylamine**',
  'octapinol*****',
  'octastine*****',
  'octatropine***',
  'octaverine****',
  'octazamide****',
  'octenidine****',
  'octimibate****',
  'octocog*******',
  'octocrilene***',
  'octodrine*****',
  'octopamine****',
  'octotiamine***',
  'octoxinol*****',
  'octriptyline**',
  'octrizole*****',
  'odanacatib****',
  'odulimomab****',
  'ofatumumab****',
  'oglufanide****',
  'olaflur*******',
  'olaquindox****',
  'olaratumab****',
  'oleandomycin**',
  'oletimol******',
  'olivomycin****',
  'olmidine******',
  'olmesartan****',
  'olodaterol****',
  'olopatadine***',
  'olpimedone****',
  'olprinone*****',
  'olradipine****',
  'olsalazine****',
  'oltipraz******',
  'olvanil*******',
  'omacetaxine***',
  'omadacycline**',
  'omapatrilat***',
  'omecamtiv*****',
  'omidoline*****',
  'omiloxetine***',
  'omtriptolide**',
  'omoconazole***',
  'omonasteine***',
  'onapristone***',
  'onercept******',
  'onrehi********',
  'ontazolast****',
  'opanixil******',
  'opebacan******',
  'opiniazide****',
  'opipramol*****',
  'opratonium****',
  'oprelvekin****',
  'orazamide*****',
  'orazipone*****',
  'orbifloxacin**',
  'orbofiban*****',
  'orbutopril****',
  'orciprenaline*',
  'orconazole****',
  'oregovomab****',
  'orestrate*****',
  'orgotein******',
  'orientiparcin*',
  'orlistat******',
  'ormaplatin****',
  'ormeloxifene**',
  'ormetoprim****',
  'ornidazole****',
  'ornipressin***',
  'ornithine*****',
  'ornoprostil***',
  'orotirelin****',
  'orpanoxin*****',
  'orphenadrine**',
  'ortetamine****',
  'orvepitant****',
  'osalmid*******',
  'osanetant*****',
  'osaterone*****',
  'osimertinib***',
  'osmadizone****',
  'ospemifene****',
  'ostreogrycin**',
  'osutidine*****',
  'otelixizumab**',
  'otenabant*****',
  'otenzepad*****',
  'otilonium*****',
  'otimerate*****',
  'ovemotide*****',
  'oxabolone*****',
  'oxabrexine****',
  'oxaceprol*****',
  'oxacillin*****',
  'oxadimedine***',
  'oxaflozane****',
  'oxaflumazine**',
  'oxagrelate****',
  'oxalinast*****',
  'oxamarin******',
  'oxametacin****',
  'oxamisole*****',
  'oxamniquine***',
  'oxanamide*****',
  'oxandrolone***',
  'oxantel*******',
  'oxapadol******',
  'oxapium*******',
  'oxapropanium**',
  'oxaprotiline**',
  'oxaprozin*****',
  'oxarbazole****',
  'oxatomide*****',
  'oxazafone*****',
  'oxazepam******',
  'oxazidione****',
  'oxazolam******',
  'oxazorone*****',
  'oxcarbazepine*',
  'oxdralazine***',
  'oxeclosporin**',
  'oxeladin******',
  'oxendolone****',
  'oxepinac******',
  'oxetacaine****',
  'oxetacillin***',
  'oxetorone*****',
  'oxfendazole***',
  'oxfenicine****',
  'oxibendazole**',
  'oxibetaine****',
  'oxiconazole***',
  'oxidopamine***',
  'oxifentorex***',
  'oxifungin*****',
  'oxiglutatione*',
  'oxilofrine****',
  'oxilorphan****',
  'oximonam******',
  'oxindanac*****',
  'oxiperomide***',
  'oxipurinol****',
  'oxiracetam****',
  'oxiramide*****',
  'oxisopred*****',
  'oxisuran******',
  'oxitefonium***',
  'oxitriptan****',
  'oxitriptyline*',
  'oxitropium****',
  'oxmetidine****',
  'oxodipine*****',
  'oxogestone****',
  'oxolamine*****',
  'oxomemazine***',
  'oxonazine*****',
  'oxophenarsine*',
  'oxoprostol****',
  'oxpheneridine*',
  'oxprenoate****',
  'oxprenolol****',
  'oxybenzone****',
  'oxybuprocaine*',
  'oxycinchophen*',
  'oxyclipine****',
  'oxyclozanide**',
  'oxydipentonium*',
  'oxyfedrine****',
  'oxyfenamate***',
  'oxymesterone**',
  'oxymetholone**',
  'oxymorphone***',
  'oxypendyl*****',
  'oxypertine****',
  'oxyphenisatine*',
  'oxyphenonium**',
  'oxypurinol****',
  'oxypyrronium**',
  'oxyridazine***',
  'oxysonium*****',
  'ozagrel*******',
  'ozarelix******',
  'ozolinone*****',
  'pacrinolol****',
  'padimate******',
  'padoporfin****',
  'pafenolol*****',
  'pafuramidine**',
  'pagibaximab***',
  'pagoclone*****',
  'palatrigine***',
  'paldimycin****',
  'palifermin****',
  'palifosfamide*',
  'palinavir*****',
  'paliroden*****',
  'palivizumab***',
  'palmidrol*****',
  'palonidipine**',
  'palovarotene**',
  'pamapimod*****',
  'pamaqueside***',
  'pamaquine*****',
  'pamatolol*****',
  'pamicogrel****',
  'pamiteplase***',
  'panadiplon****',
  'panamesine****',
  'pancopride****',
  'panidazole****',
  'panipenem*****',
  'panitumumab***',
  'panomifene****',
  'pantenicate***',
  'panthenol*****',
  'pantoprazole**',
  'panuramine****',
  'papaveroline**',
  'paraflutizide*',
  'paramethadione*',
  'paramethasone*',
  'parapenzolate*',
  'parapropamol**',
  'pararosaniline*',
  'parathiazine**',
  'paraxazone****',
  'parbendazole**',
  'parcetasal****',
  'parconazole***',
  'pareptide*****',
  'parethoxycaine*',
  'pardoprunox***',
  'pargeverine***',
  'pargolol******',
  'pargyline*****',
  'paricalcitol**',
  'paridocaine***',
  'parnaparin****',
  'parodilol*****',
  'paroxypropione*',
  'parsalmide****',
  'partricin*****',
  'parvaquone****',
  'pascolizumab**',
  'pasiniazid****',
  'pasireotide***',
  'patamostat****',
  'paulomycin****',
  'paxamate******',
  'patupilone****',
  'pazelliptine**',
  'pazinaclone***',
  'pazoxide******',
  'pazufloxacin**',
  'pecazine******',
  'pecilocin*****',
  'pecocycline***',
  'pefloxacin****',
  'peforelin*****',
  'pegacaristim**',
  'pegaldesleukin*',
  'pegamotecan***',
  'pegaptanib****',
  'pegdinetanib**',
  'pegfilgrastim*',
  'peginesatide**',
  'peginterferon*',
  'pegloticase***',
  'pegmusirudin**',
  'pegorgotein***',
  'pegsunercept**',
  'pegvisomant***',
  'pelanserin****',
  'peldesine*****',
  'peliglitazar**',
  'peliomycin****',
  'pelitinib*****',
  'pelitrexol****',
  'pelretin******',
  'pelrinone*****',
  'pelubiprofen**',
  'pemedolac*****',
  'pemerid*******',
  'pemirolast****',
  'pemoline******',
  'pempidine*****',
  'pemtumomab****',
  'penamecillin**',
  'penbutolol****',
  'penciclovir***',
  'pendecamaine**',
  'pendetide*****',
  'penfluridol***',
  'penflutizide**',
  'pengitoxin****',
  'penicillinase*',
  'penimocycline*',
  'penirolol*****',
  'penmesterol***',
  'penoctonium***',
  'penprostene***',
  'pentabamate***',
  'pentacynium***',
  'pentafluranol*',
  'pentagastrin**',
  'pentagestrone*',
  'pentalamide***',
  'pentamethonium*',
  'pentamorphone*',
  'pentamoxane***',
  'pentapiperide*',
  'pentapiperium*',
  'pentaquine****',
  'pentazocine***',
  'pentetrazol***',
  'pentetreotide*',
  'penthrichloral*',
  'pentiapine****',
  'pentifylline**',
  'pentigetide***',
  'pentisomicin**',
  'pentisomide***',
  'pentizidone***',
  'pentobarbital*',
  'pentolonium***',
  'pentomone*****',
  'pentopril*****',
  'pentorex******',
  'pentosan******',
  'pentostatin***',
  'pentoxifylline*',
  'pentoxyverine*',
  'pentrinitrol**',
  'peplomycin****',
  'pepstatin*****',
  'peraclopone***',
  'peradoxime****',
  'perafensine***',
  'peralopride***',
  'peramivir*****',
  'perampanel****',
  'peraquinsin***',
  'perastine*****',
  'peratizole****',
  'perbufylline**',
  'perfluamine***',
  'perflubrodec**',
  'perflubron****',
  'perflubutane**',
  'perflunafene**',
  'perfomedil****',
  'perfosfamide**',
  'pergolide*****',
  'perhexiline***',
  'periciazine***',
  'perifosine****',
  'perimetazine**',
  'perindopril***',
  'perindoprilat*',
  'perisoxal*****',
  'perlapine*****',
  'perospirone***',
  'perphenazine**',
  'petrichloral**',
  'pexacerfont***',
  'pexantel******',
  'pexelizumab***',
  'pexiganan*****',
  'phanquinone***',
  'phenacaine****',
  'phenacemide***',
  'phenacetin****',
  'phenadoxone***',
  'phenaglycodol*',
  'phenamazoline*',
  'phenampromide*',
  'phenarsone****',
  'phenazocine***',
  'phenazone*****',
  'phencyclidine*',
  'phenelzine****',
  'pheneridine***',
  'pheneticillin*',
  'pheneturide***',
  'phenformin****',
  'phenicarbazide*',
  'phenindamine**',
  'phenindione***',
  'pheniodol*****',
  'pheniprazine**',
  'pheniramine***',
  'phenmetrazine*',
  'phenobutiodil*',
  'phenomorphan**',
  'phenoperidine*',
  'phenothiazine*',
  'phenothrin****',
  'phenprobamate*',
  'phenprocoumon*',
  'phensuximide**',
  'phentermine***',
  'phentolamine**',
  'phenylalanine*',
  'phenylbutazone*',
  'phenylmercuric*',
  'phenyracillin*',
  'phenythilone**',
  'phetharbital**',
  'pholcodine****',
  'pholedrine****',
  'phoxim********',
  'phytonadiol***',
  'pibaxizine****',
  'pibecarb******',
  'piberaline****',
  'pibutidine****',
  'picafibrate***',
  'picartamide***',
  'picenadol*****',
  'picilorex*****',
  'piclamilast***',
  'piclonidine***',
  'piclopastine**',
  'picloxydine***',
  'picobenzide***',
  'picodralazine*',
  'picolamine****',
  'piconol*******',
  'picoperine****',
  'picoplatin****',
  'picoprazole***',
  'picotrin******',
  'picumast******',
  'picumeterol***',
  'pidobenzone***',
  'pidolacetamol*',
  'pidotimod*****',
  'pifarnine*****',
  'pifenate******',
  'pifexole******',
  'piflutixol****',
  'pifonakin*****',
  'pifoxime******',
  'piketoprofen**',
  'pildralazine**',
  'pilsicainide**',
  'pimavanserin**',
  'pimeclone*****',
  'pimefylline***',
  'pimelautide***',
  'pimetacin*****',
  'pimethixene***',
  'pimetine******',
  'pimetremide***',
  'pimilprost****',
  'piminodine****',
  'pimobendan****',
  'pimonidazole**',
  'pimozide******',
  'pinacidil*****',
  'pinadoline****',
  'pinafide******',
  'pinaverium****',
  'pinazepam*****',
  'pincainide****',
  'pindolol******',
  'pinolcaine****',
  'pinoxepin*****',
  'pioglitazone**',
  'pipacycline***',
  'pipamazine****',
  'pipamperone***',
  'pipazetate****',
  'pipebuzone****',
  'pipecuronium**',
  'pipemidic*****',
  'pipenzolate***',
  'pipequaline***',
  'piperacetazine*',
  'piperamide****',
  'piperazine****',
  'piperidolate**',
  'piperocaine***',
  'piperoxan*****',
  'piperylone****',
  'pipethanate***',
  'pipobroman****',
  'pipoctanone***',
  'pipofezine****',
  'piposulfan****',
  'pipotiazine***',
  'pipoxizine****',
  'pipoxolan*****',
  'pipradimadol**',
  'pipradrol*****',
  'pipramadol****',
  'pipratecol****',
  'piprocurarium*',
  'piprofurol****',
  'piquindone****',
  'piquizil******',
  'piracetam*****',
  'piragliatin***',
  'pirandamine***',
  'pirarubicin***',
  'piraxelate****',
  'pirazmonam****',
  'pirazofurin***',
  'pirazolac*****',
  'pirbenicillin*',
  'pirbuterol****',
  'pirdonium*****',
  'pirenoxine****',
  'pirenperone***',
  'pirenzepine***',
  'pirepolol*****',
  'piretanide****',
  'pirfenidone***',
  'piribedil*****',
  'piridicillin**',
  'piridocaine***',
  'piridoxilate**',
  'pirifibrate***',
  'pirinidazole**',
  'pirinixil*****',
  'piriprost*****',
  'piriqualone***',
  'pirisudanol***',
  'piritramide***',
  'piritrexim****',
  'pirlimycin****',
  'pirlindole****',
  'pirmagrel*****',
  'pirmenol******',
  'pirnabin******',
  'piroctone*****',
  'pirodavir*****',
  'pirodomast****',
  'pirogliride***',
  'piroheptine***',
  'pirolate******',
  'pirolazamide**',
  'piroxantrone**',
  'piroxicillin**',
  'piroximone****',
  'pirozadil*****',
  'pirprofen*****',
  'pirquinozol***',
  'pirralkonium**',
  'pirsidomine***',
  'pirtenidine***',
  'pitenodil*****',
  'pitofenone****',
  'pitolisant****',
  'pitrakinra****',
  'pituxate******',
  'pivagabine****',
  'pivampicillin*',
  'pivenfrine****',
  'pivmecillinam*',
  'pivopril******',
  'pivoxazepam***',
  'pixantrone****',
  'pizotifen*****',
  'plafibride****',
  'plaunotol*****',
  'plauracin*****',
  'pleconaril****',
  'plerixafor****',
  'pleuromulin***',
  'plicamycin****',
  'plinabulin****',
  'plitidepsin***',
  'plomestane****',
  'plusonermin***',
  'pobilukast****',
  'podilfen******',
  'polacrilin****',
  'polaprezinc***',
  'poldine*******',
  'policresulen**',
  'polidexide****',
  'polidronium***',
  'polifeprosan**',
  'poligeenan****',
  'poliglecaprone*',
  'poliglusam****',
  'polihexanide**',
  'polisaponin***',
  'polixetonium**',
  'poloxalene****',
  'poloxamer*****',
  'polybenzarsol*',
  'polycarbophil*',
  'polyestradiol*',
  'polyetadene***',
  'polygeline****',
  'polymyxin*****',
  'polynoxylin***',
  'polysorbate***',
  'polythiazide**',
  'pomalidomide**',
  'pomisartan****',
  'ponalrestat***',
  'ponezumab*****',
  'ponfibrate****',
  'porfiromycin**',
  'posatirelin***',
  'poskine*******',
  'povidone******',
  'pozanicline***',
  'practolol*****',
  'pradefovir****',
  'prajmalium****',
  'pralatrexate**',
  'pralmorelin***',
  'pralnacasan***',
  'pramiconazole*',
  'pramiracetam**',
  'pramiverine***',
  'pramlintide***',
  'pramocaine****',
  'prampine******',
  'pranazepide***',
  'pranidipine***',
  'pranlukast****',
  'pranolium*****',
  'pranoprofen***',
  'prasterone****',
  'pravadoline***',
  'praxadine*****',
  'prazarelix****',
  'prazepam******',
  'prazepine*****',
  'prazitone*****',
  'prazocillin***',
  'preclamol*****',
  'prednazate****',
  'prednazoline**',
  'prednicarbate*',
  'prednimustine*',
  'prednisolamate*',
  'prednylidene**',
  'prefenamate***',
  'pregabalin****',
  'pregnenolone**',
  'preladenant***',
  'premafloxacin*',
  'premazepam****',
  'prenalterol***',
  'prenisteine***',
  'prenoverine***',
  'prenoxdiazine*',
  'prenylamine***',
  'pretamazium***',
  'pretiadil*****',
  'prezatide*****',
  'pribecaine****',
  'pridefine*****',
  'prideperone***',
  'pridinol******',
  'pridopidine***',
  'prifelone*****',
  'prifinium*****',
  'prifuroline***',
  'priliximab****',
  'prilocaine****',
  'primaperone***',
  'primidolol****',
  'primidone*****',
  'primycin******',
  'prinaberel****',
  'prinomide*****',
  'prinoxodan****',
  'prisotinol****',
  'pristinamycin*',
  'pritumumab****',
  'prizidilol****',
  'probarbital***',
  'probucol******',
  'procaterol****',
  'procinolol****',
  'procinonide***',
  'proclonol*****',
  'procodazole***',
  'procyclidine**',
  'procymate*****',
  'prodeconium***',
  'prodilidine***',
  'prodipine*****',
  'profadol******',
  'profenamine***',
  'profexalone***',
  'proflavine****',
  'proflazepam***',
  'progabide*****',
  'proglumetacin*',
  'proglumide****',
  'proheptazine**',
  'proligestone**',
  'proline*******',
  'prolintane****',
  'prolonium*****',
  'promazine*****',
  'promegestone**',
  'promelase*****',
  'promestriene**',
  'promolate*****',
  'promoxolane***',
  'pronetalol****',
  'propacetamol**',
  'pumaprazole***',
  'propagermanium*',
  'propamidine***',
  'propd*********',
  'propanocaine**',
  'propantheline*',
  'propenidazole*',
  'proylline*****',
  'properidine***',
  'propetamide***',
  'propetandrol**',
  'propicillin***',
  'propikacin****',
  'propinetidine*',
  'propiolactone*',
  'propiomazine**',
  'propipocaine**',
  'propiram******',
  'propisergide**',
  'propiverine***',
  'propizepine***',
  'propoxate*****',
  'propoxycaine**',
  'propyliodone**',
  'propyperone***',
  'propyphenazone*',
  'proquazone****',
  'proquinolate**',
  'prorenoate****',
  'proroxan******',
  'proscillaridin*',
  'prospidium****',
  'prostalene****',
  'prosulpride***',
  'prosultiamine*',
  'proterguride**',
  'protheobromine*',
  'prothipendyl**',
  'prothixene****',
  'protiofate****',
  'protionamide**',
  'protirelin****',
  'protokylol****',
  'protriptyline*',
  'proxazole*****',
  'proxibarbal***',
  'proxibutene***',
  'proxicromil***',
  'proxifezone***',
  'proxorphan****',
  'proxymetacaine*',
  'proxyphylline*',
  'prozapine*****',
  'prucalopride**',
  'prulifloxacin*',
  'pruvanserin***',
  'psilocybine***',
  'pulmonol******',
  'pumitepa******',
  'pumosetrag****',
  'puromycin*****',
  'pyricarbate***',
  'pyridarone****',
  'pyridofylline*',
  'pyrimitate****',
  'pyrinoline****',
  'pyrithyldione*',
  'pyritidium****',
  'pyritinol*****',
  'pyrophendane**',
  'pyrovalerone**',
  'pyroxamine****',
  'pyrrocaine****',
  'pyrrolifene***',
  'pyrrolnitrin**',
  'pyrvinium*****',
  'pytamine******',
  'quadazocine***',
  'quadrosilan***',
  'quatacaine****',
  'quazepam******',
  'quazinone*****',
  'quazodine*****',
  'quazolast*****',
  'quifenadine***',
  'quiflapon*****',
  'quillifoline**',
  'quilostigmine*',
  'quinacainol***',
  'quinacillin***',
  'quinagolide***',
  'quinapril*****',
  'quinaprilat***',
  'quinazosin****',
  'quinbolone****',
  'quincarbate***',
  'quindecamine**',
  'quindonium****',
  'quindoxin*****',
  'quinelorane***',
  'quinestradol**',
  'quinestrol****',
  'quinetalate***',
  'quinethazone**',
  'quinezamide***',
  'quinfamide****',
  'quingestanol**',
  'quingestrone**',
  'quinisocaine**',
  'quinocide*****',
  'quinotolast***',
  'quinpirole****',
  'quinprenaline*',
  'quintiofos****',
  'quinuclium****',
  'quinupramine**',
  'quinupristin**',
  'quipazine*****',
  'quisultazine**',
  'quitiapine****',
  'quizartinib***',
  'rabeprazole***',
  'rabeximod*****',
  'racecadotril**',
  'racefemine****',
  'racefenicol***',
  'racementhol***',
  'racemethorphan*',
  'racemetirosine*',
  'racemoramide**',
  'racemorphan***',
  'racephedrine**',
  'racepinefrine*',
  'raclopride****',
  'racotumomab***',
  'ractalind*****',
  'ractopamine***',
  'radafaxine****',
  'radavirsen****',
  'radezolid*****',
  'radotinib*****',
  'radretumab****',
  'rafivirumab***',
  'rafoxanide****',
  'ralitoline****',
  'ralitrexed****',
  'raloxifene****',
  'ramatroban****',
  'ramciclane****',
  'ramelteon*****',
  'ramifenazone**',
  'ramipril******',
  'ramiprilat****',
  'ramixotidine**',
  'ramnodigin****',
  'ramoplanin****',
  'ramorelix*****',
  'ramosetron****',
  'ramucirumab***',
  'ranimustine***',
  'ranimycin*****',
  'ranolazine****',
  'ranpirnase****',
  'rapacuronium**',
  'rasagiline****',
  'raseglurant***',
  'rathyronine***',
  'ravatirelin***',
  'raxibacumab***',
  'raxofelast****',
  'razinodil*****',
  'razobazam*****',
  'razoxane******',
  'razupenem*****',
  'rebamipide****',
  'rebimastat****',
  'reboxetine****',
  'recainam******',
  'recigen*******',
  'reclazepam****',
  'recoflavone***',
  'regadenoson***',
  'regavirumab***',
  'regramostim***',
  'regrelor******',
  'relacatib*****',
  'relcovaptan***',
  'relomycin*****',
  'remacemide****',
  'remifentanil**',
  'remikiren*****',
  'remimazolam***',
  'reminertant***',
  'remiprostol***',
  'remoglifozin**',
  'remoxipride***',
  'renanolone****',
  'rentiapril****',
  'renytoline****',
  'renzapride****',
  'repagermanium*',
  'repaglinide***',
  'repifermin****',
  'repirinast****',
  'repromicin****',
  'reproterol****',
  'resatorvid****',
  'rescimetol****',
  'rescinnamine**',
  'reserpine*****',
  'reslizumab****',
  'resminostat***',
  'resocortol****',
  'resorantel****',
  'retapamulin***',
  'retaspimycin**',
  'retelliptine**',
  'reteplase*****',
  'retigabine****',
  'retosiban*****',
  'revamilast****',
  'revatropate***',
  'revenast******',
  'reviparin*****',
  'revizinone****',
  'revospirone***',
  'rezatomidine**',
  'ribaminol*****',
  'riboprine*****',
  'ribostamycin**',
  'ricasetron****',
  'ridaforolimus*',
  'ridazolol*****',
  'ridogrel******',
  'rifalazil*****',
  'rifametane****',
  'rifamexil*****',
  'rifamide******',
  'rifampin******',
  'rifamycin*****',
  'riferminogene*',
  'rilapine******',
  'rilmakalim****',
  'rilmazafone***',
  'rilmenidine***',
  'rilonacept****',
  'rilopirox*****',
  'rilotumumab***',
  'rilozarone****',
  'rilpirivine***',
  'riluzole******',
  'rimantadine***',
  'rimazolium****',
  'rimcazole*****',
  'rimexolone****',
  'rimiterol*****',
  'rimonabant****',
  'rimoprogin****',
  'rintatolimod**',
  'riociguat*****',
  'riodipine*****',
  'rioprostil****',
  'ripazepam*****',
  'ripisartan****',
  'rismorelin****',
  'risocaine*****',
  'risotilide****',
  'rispenzepine**',
  'ristianol*****',
  'ristocetin****',
  'ritanserin****',
  'ritiometan****',
  'ritipenem*****',
  'ritodrine*****',
  'ritolukast****',
  'ritropirronium',
  'ritrosulfan***',
  'rivanicline***',
  'rivenprost****',
  'rizatriptan***',
  'rizolipase****',
  'robalzotan****',
  'robatumumab***',
  'robenidine****',
  'rocastine*****',
  'rocepafant****',
  'rociclovir****',
  'rociverine****',
  'rocuronium****',
  'rodocaine*****',
  'rodorubicin***',
  'rofecoxib*****',
  'rofelodine****',
  'rofleponide***',
  'roflumilast***',
  'roflurane*****',
  'rogletimide***',
  'rokitamycin***',
  'rolafagrel****',
  'rolapitant****',
  'roledumab*****',
  'roletamide****',
  'rolgamidine***',
  'rolicyclidine*',
  'rolicyprine***',
  'rolipoltide***',
  'rolipram******',
  'rolodine******',
  'rolofylline***',
  'rolziracetam**',
  'romazarit*****',
  'romergoline***',
  'romidepsin****',
  'romifenone****',
  'romifidine****',
  'romiplostim***',
  'romosozumab***',
  'romurtide*****',
  'ronacaleret***',
  'ronactolol****',
  'ronidazole****',
  'ronifibrate***',
  'ronipamil*****',
  'ronomilast****',
  'rontalizumab**',
  'ropidoxuridine',
  'ropitoin******',
  'ropivacaine***',
  'ropizine******',
  'roquinimex****',
  'rosabulin*****',
  'rosaprostol***',
  'rosaramicin***',
  'rosiglitazone*',
  'rosoxacin*****',
  'rosterolone***',
  'rosuvastatin**',
  'rotamicillin**',
  'rotigaptide***',
  'rotigotine****',
  'rotoxamine****',
  'rotraxate*****',
  'rovelizumab***',
  'roxadimate****',
  'roxarsone*****',
  'roxatidine****',
  'roxibolone****',
  'roxifiban*****',
  'roxindole*****',
  'roxithromycin*',
  'roxolonium****',
  'roxoperone****',
  'rozrolimupab**',
  'rubidomycin***',
  'rubitecan*****',
  'ruboxistaurin*',
  'rucaparib*****',
  'rufinamide****',
  'rufloxacin****',
  'rufocromomycin*',
  'rupatadine****',
  'rupintrivir***',
  'ruplizumab****',
  'rutamycin*****',
  'rutoside******',
  'ruvazone******',
  'ruxolitinib***',
  'ruzadolane****',
  'sabcomeline***',
  'sabeluzole****',
  'safingol******',
  'safironil*****',
  'safotibant****',
  'sagandipine***',
  'sagopilone****',
  'salacetamide**',
  'salafibrate***',
  'salantel******',
  'salazodine****',
  'salcolex******',
  'saletamide****',
  'salfluverine**',
  'salicylamide**',
  'salinazid*****',
  'salinomycin***',
  'salirasib*****',
  'salmefamol****',
  'salmisteine***',
  'salnacedin****',
  'salprotoside**',
  'salsalate*****',
  'salverine*****',
  'samalizumab***',
  'samarium******',
  'sameridine****',
  'samixogrel****',
  'sampatrilat***',
  'sampirtine****',
  'sancycline****',
  'sanfetrinem***',
  'sanguinarium**',
  'sapacitabine**',
  'saperconazole*',
  'saprisartan***',
  'sapropterin***',
  'saquinavir****',
  'saracatinib***',
  'sarafloxacin**',
  'saralasin*****',
  'sarcolysin****',
  'saredutant****',
  'sargramostim**',
  'sarilumab*****',
  'saripidem*****',
  'sarizotan*****',
  'sarmazenil****',
  'sarmoxicillin*',
  'sarpicillin***',
  'sarpogrelate**',
  'saruplase*****',
  'satavaptan****',
  'saterinone****',
  'satigrel******',
  'satranidazole*',
  'satumomab*****',
  'saviprazole***',
  'savoxepin*****',
  'saxagliptin***',
  'scopolamine***',
  'sebriplatin***',
  'secalciferol**',
  'seclazone*****',
  'secnidazole***',
  'secobarbital**',
  'secoverine****',
  'secretin******',
  'secukinumab***',
  'securinine****',
  'sedecamycin***',
  'seganserin****',
  'seglitide*****',
  'selegiline****',
  'selepressin***',
  'seletracetam**',
  'selexipag*****',
  'selfotel******',
  'seliciclib****',
  'selodenoson***',
  'selprazine****',
  'seltraposin***',
  'selumetinib***',
  'selurampanel**',
  'semagacestat**',
  'semaglutide***',
  'sematilide****',
  'semduramicin**',
  'semorphone****',
  'semotiadil****',
  'semparatide***',
  'semuloparin***',
  'semustine*****',
  'senicapoc*****',
  'seocalcitol***',
  'sepantronium**',
  'sepazonium****',
  'sepimostat****',
  'seprilose*****',
  'seproxetine***',
  'sequifenadine*',
  'seractide*****',
  'seratrodast***',
  'serazapine****',
  'serdemetan****',
  'serelaxin*****',
  'serfibrate****',
  'sergliflozin**',
  'sergolexole***',
  'seridopidine**',
  'serine********',
  'serlopitant***',
  'sermetacin****',
  'sermorelin****',
  'serrapeptase**',
  'sertaconazole*',
  'sertindole****',
  'sertraline****',
  'setastine*****',
  'setazindol****',
  'setileuton****',
  'setipafant****',
  'setipiprant***',
  'setiptiline***',
  'setoperone****',
  'setrobuvir****',
  'sevirumab*****',
  'sevitropium***',
  'sevoflurane***',
  'sevopramide***',
  'sezolamide****',
  'sfericase*****',
  'siagoside*****',
  'sibenadet*****',
  'sibopirdine***',
  'sibrafiban****',
  'sibrotuzumab**',
  'sibutramine***',
  'siccanin******',
  'sifalimumab***',
  'sifaprazine***',
  'siguazodan****',
  'silandrone****',
  'sildenafil****',
  'silibinin*****',
  'silicristin***',
  'silidianin****',
  'silmitasertib*',
  'silperisone***',
  'siltenzepine**',
  'silteplase****',
  'siltuximab****',
  'simaldrate****',
  'simenepag*****',
  'simendan******',
  'simeprevir****',
  'simetride*****',
  'simfibrate****',
  'simoctocog****',
  'simotaxel*****',
  'simtrazene****',
  'sinapultide***',
  'sincalide*****',
  'sinefungin****',
  'sinitrodil****',
  'sintropium****',
  'sipatrigine***',
  'siplizumab****',
  'sipoglitazar**',
  'siratiazem****',
  'sirukumab*****',
  'sisomicin*****',
  'sitafloxacin**',
  'sitagliptin***',
  'sitalidone****',
  'ceradenovec***',
  'sitimagene****',
  'sitofibrate***',
  'sitogluside***',
  'sivelestat****',
  'sivifene******',
  'sizofiran*****',
  'sobetirome****',
  'sobuzoxane****',
  'sofalcone*****',
  'sofigatran****',
  'sofinicline***',
  'solabegron****',
  'solanezumab***',
  'solasulfone***',
  'solithromycin*',
  'solpecainol***',
  'solypertine***',
  'somagrebove***',
  'somalapor*****',
  'somantadine***',
  'somatorelin***',
  'somatosalm****',
  'somatrem******',
  'somavubove****',
  'somenopor*****',
  'somfasepor****',
  'somidobove****',
  'sonedonoson***',
  'sonepcizumab**',
  'sonermin******',
  'sontuzumab****',
  'sopitazine****',
  'sopromidine***',
  'soquinolol****',
  'sorbinicate***',
  'sorbinil******',
  'sorbitan******',
  'soretolide****',
  'sorivudine****',
  'sornidipine***',
  'sotatercept***',
  'soterenol*****',
  'sotirimod*****',
  'sotrastaurin**',
  'sparfloxacin**',
  'sparsomycin***',
  'sparteine*****',
  'spiclamine****',
  'spiclomazine**',
  'spiperone*****',
  'spiradoline***',
  'spiramide*****',
  'spiramycin****',
  'spirapril*****',
  'spiraprilat***',
  'spirazine*****',
  'spirendolol***',
  'spirgetine****',
  'spirilene*****',
  'spiriprostil**',
  'spirofylline**',
  'spirogermanium*',
  'spiroglumide**',
  'spiromustine**',
  'spiroplatin***',
  'spirorenone***',
  'spiroxasone***',
  'spiroxatrine**',
  'spiroxepin****',
  'spizofurone***',
  'sprifermin****',
  'sprodiamide***',
  'squalamine****',
  'stacofylline**',
  'stallimycin***',
  'stamulumab****',
  'stanozolol****',
  'stavudine*****',
  'steffimycin***',
  'stenbolone****',
  'stepronin*****',
  'stercuronium**',
  'stevaladil****',
  'stibamine*****',
  'stibosamine***',
  'stilbamidine**',
  'stirimazole***',
  'stirocainide**',
  'streptodornase*',
  'streptoniazid*',
  'streptovarycin*',
  'streptozocin**',
  'strinoline****',
  'styramate*****',
  'subathizone***',
  'subendazole***',
  'succimer******',
  'succinobucol**',
  'succisulfone**',
  'suclofenide***',
  'sucralfate****',
  'sucralox******',
  'sucrosofate***',
  'sudexanox*****',
  'sudismase*****',
  'sudoxicam*****',
  'sufentanil****',
  'sufosfamide***',
  'sufotidine****',
  'sufugolix*****',
  'sugammadex****',
  'sulazepam*****',
  'sulazuril*****',
  'sulbactam*****',
  'sulbenicillin*',
  'sulbenox******',
  'sulbentine****',
  'sulbutiamine**',
  'sulclamide****',
  'sulconazole***',
  'suleparoid****',
  'sulesomab*****',
  'sulfabenz*****',
  'sulfabenzamide*',
  'sulfacarbamide*',
  'sulfacecole***',
  'sulfacetamide*',
  'sulfacitine***',
  'sulfaclomide**',
  'sulfaclorazole*',
  'sulfaclozine**',
  'sulfadicramide*',
  'sulfadimidine*',
  'sulfaethidole*',
  'sulfafurazole*',
  'sulfaguanidine*',
  'sulfaguanole**',
  'sulfalene*****',
  'sulfamazone***',
  'sulfamerazine*',
  'sulfamethizole*',
  'sulfametrole**',
  'sulfamoxole***',
  'sulfanilamide*',
  'sulfanitran***',
  'sulfaperin****',
  'sulfaphenazole*',
  'sulfaproxyline*',
  'sulfapyrazole*',
  'sulfapyridine*',
  'sulfasomizole*',
  'sulfasymazine*',
  'sulfathiazole*',
  'sulfathiourea*',
  'sulfatolamide*',
  'sulfatroxazole*',
  'sulfatrozole**',
  'sulfinalol****',
  'sulfinpyrazone*',
  'sulfiram******',
  'sulfisomidine*',
  'sulfogaiacol**',
  'sulfomyxin****',
  'sulfonterol***',
  'sulforidazine*',
  'sulglicotide**',
  'sulicrinat****',
  'sulindac******',
  'sulisatin*****',
  'sulisobenzone*',
  'sulmarin******',
  'sulmazole*****',
  'sulmepride****',
  'sulnidazole***',
  'sulocarbilate*',
  'suloctidil****',
  'sulodexide****',
  'sulofenur*****',
  'sulopenem*****',
  'sulosemide****',
  'sulotroban****',
  'suloxifen*****',
  'sulparoid*****',
  'sulpiride*****',
  'sulprosal*****',
  'sulprostone***',
  'sultamicillin*',
  'sultiame******',
  'sultopride****',
  'sultroponium**',
  'sulukast******',
  'sulverapride**',
  'sumacetamol***',
  'sumarotene****',
  'sumetizide****',
  'sunagrel******',
  'suncillin*****',
  'sunepitron****',
  'sunitinib*****',
  'supidimide****',
  'suplatast*****',
  'suproclone****',
  'suprofen******',
  'surfomer******',
  'suricainide***',
  'suriclone*****',
  'surinabant****',
  'suritozole****',
  'suronacrine***',
  'susalimod*****',
  'sutezolid*****',
  'sutilains*****',
  'suvizumab*****',
  'suvorexant****',
  'suxemerid*****',
  'suxethonium***',
  'suxibuzone****',
  'symclosene****',
  'symetine******',
  'syrosingopine*',
  'tabalumab*****',
  'tabilautide***',
  'tabimorelin***',
  'tacedinaline**',
  'taclamine*****',
  'tacrine*******',
  'tadalafil*****',
  'tadekinig*****',
  'tadocizumab***',
  'tafamidis*****',
  'tafluprost****',
  'tafoxiparin***',
  'taglutimide***',
  'tagorizine****',
  'talactoferrin*',
  'talaglumetad**',
  'talampanel****',
  'talampicillin*',
  'talaporfin****',
  'talarozole****',
  'talastine*****',
  'talbutal******',
  'taleranol*****',
  'talibegron****',
  'taliglucerase*',
  'talimogene****',
  'talinolol*****',
  'talipexole****',
  'talisomycin***',
  'talizumab*****',
  'tallimustine**',
  'talmapimod****',
  'talmetacin****',
  'talmetoprim***',
  'talniflumate**',
  'talopram******',
  'talosalate****',
  'talotrexin****',
  'taloximine****',
  'talsaclidine**',
  'talsupram*****',
  'taltirelin****',
  'taltobulin****',
  'taltrimide****',
  'taluglucerase*',
  'talviraline***',
  'tameridone****',
  'tameticillin**',
  'tametraline***',
  'tamibarotene**',
  'tamitinol*****',
  'tamixaban*****',
  'tamolarizine**',
  'tampramine****',
  'tanaproget****',
  'tandamine*****',
  'tandospirone**',
  'tandutinib****',
  'taneptacogin**',
  'tanespimycin**',
  'tanezumab*****',
  'taniplon******',
  'tapentadol****',
  'taplitumomab**',
  'taprenepag****',
  'taprostene****',
  'taranabant****',
  'tarazepide****',
  'tarenflurbil**',
  'targinine*****',
  'taribavirin***',
  'tariquidar****',
  'tasidotin*****',
  'tasimelteon***',
  'tasisulam*****',
  'tasocitinib***',
  'tasonermin****',
  'tasosartan****',
  'taspoglutide**',
  'tasquinimod***',
  'tasuldine*****',
  'taurine*******',
  'taurolidine***',
  'tauromustine**',
  'taurosteine***',
  'taurultam*****',
  'tavaborole****',
  'tazadolene****',
  'tazanolast****',
  'tazarotene****',
  'tazasubrate***',
  'tazeprofen****',
  'tazifylline***',
  'taziprinone***',
  'tazofelone****',
  'tazolol*******',
  'tazomeline****',
  'tebanicline***',
  'tebatizole****',
  'tebipenem*****',
  'tebufelone****',
  'tebuquine*****',
  'tecadenoson***',
  'tecarfarin****',
  'tecastemizole*',
  'teceleukin****',
  'technetium****',
  'teclothiazide*',
  'teclozan******',
  'tecovirimat***',
  'tedalinab*****',
  'tedisamil*****',
  'tedizolid*****',
  'teduglutide***',
  'tefazoline****',
  'tefenperate***',
  'tefibazumab***',
  'tefinostat****',
  'tefludazine***',
  'teflurane*****',
  'teflutixol****',
  'tegafur*******',
  'tegaserod*****',
  'teglarinad****',
  'tegobuvir*****',
  'teicoplanin***',
  'telaprevir****',
  'telapristone**',
  'telatinib*****',
  'telavancin****',
  'telbermin*****',
  'telbivudine***',
  'telcagepant***',
  'telenzepine***',
  'telimomab*****',
  'telinavir*****',
  'telithromycin*',
  'telmesteine***',
  'telotristat***',
  'teloxantrone**',
  'teludipine****',
  'temafloxacin**',
  'temanogrel****',
  'temarotene****',
  'tematropium***',
  'temazepam*****',
  'temefos*******',
  'temelastine***',
  'temiverine****',
  'temocapril****',
  'temocaprilat**',
  'temocillin****',
  'temodox*******',
  'temoporfin****',
  'temsirolimus**',
  'temurtide*****',
  'tenamfetamine*',
  'tenatumomab***',
  'tendamistat***',
  'tenecteplase**',
  'tenegliptin***',
  'teneliximab***',
  'tenidap*******',
  'tenifatecan***',
  'tenilapine****',
  'teniloxazine**',
  'tenilsetam****',
  'teniposide****',
  'tenivastatin**',
  'tenocyclidine*',
  'tenonitrozole*',
  'tenosal*******',
  'tenosiprol****',
  'tenoxicam*****',
  'tenylidone****',
  'teopranitol***',
  'teoprolol*****',
  'tepirindole***',
  'teplizumab****',
  'tepoxalin*****',
  'teprenone*****',
  'teprotide*****',
  'teprotumumab**',
  'terameprocol**',
  'terbequinil***',
  'terbogrel*****',
  'terbucromil***',
  'terbufibrol***',
  'terbuficin****',
  'terbuprol*****',
  'terciprazine**',
  'terconazole***',
  'terdecamycin**',
  'tererstigmine*',
  'terfenadine***',
  'terflavoxate**',
  'terfluranol***',
  'terguride*****',
  'teriflunomide*',
  'terikalant****',
  'teriparatide**',
  'terizidone****',
  'terlakiren****',
  'terlipressin**',
  'ternidazole***',
  'terodiline****',
  'terofenamate**',
  'teroxalene****',
  'teroxirone****',
  'tertatolol****',
  'tertomotide***',
  'terutroban****',
  'tesaglitazar**',
  'tesamorelin***',
  'teserstigmine*',
  'tesetaxel*****',
  'tesicam*******',
  'tesimide******',
  'testolactone**',
  'tetrabarbital*',
  'tetrabenazine*',
  'tetracosactide*',
  'tetracycline**',
  'tetradonium***',
  'tetramethrin**',
  'tetramisole***',
  'tetrazepam****',
  'tetrazolast***',
  'tetridamine***',
  'tetriprofen***',
  'tetrofosmin***',
  'tetronasin****',
  'tetroquinone**',
  'tetroxoprim***',
  'tetrylammonium*',
  'teverelix*****',
  'texacromil****',
  'tezampanel****',
  'thebacon******',
  'thenalidine***',
  'thenium*******',
  'thenyldiamine*',
  'theodrenaline*',
  'thialbarbital*',
  'thiamazole****',
  'thiambutosine*',
  'thiamphenicol*',
  'thiazinamium**',
  'thiazosulfone*',
  'thihexinol****',
  'thioacetazone*',
  'thiofuradene**',
  'thiohexamide**',
  'thiomersal****',
  'thiopropazate*',
  'thioridazine**',
  'thiotepa******',
  'thiram********',
  'thonzylamine**',
  'thrombin******',
  'thrombomodulin*',
  'thymalfasin***',
  'thymocartin***',
  'thymoctonan***',
  'thymopentin***',
  'thymostimulin*',
  'thymotrinan***',
  'thyroglobulin*',
  'thyrotrophin**',
  'tiabendazole**',
  'tiacrilast****',
  'tiadenol******',
  'tiafibrate****',
  'tiagabine*****',
  'tiamenidine***',
  'tiametonium***',
  'tiamiprine****',
  'tiamizide*****',
  'tiamulin******',
  'tianafac******',
  'tianeptine****',
  'tiapamil******',
  'tiapirinol****',
  'tiapride******',
  'tiaprost******',
  'tiaramide*****',
  'tiazesim******',
  'tiazofurine***',
  'tiazuril******',
  'tibalosin*****',
  'tibeglisene***',
  'tibenelast****',
  'tibenzate*****',
  'tibezonium****',
  'tibolone******',
  'tibrofan******',
  'ticabesone****',
  'ticarbodine***',
  'ticarcillin***',
  'ticlatone*****',
  'ticolubant****',
  'tideglusib****',
  'tidembersat***',
  'tidiacic******',
  'tiemonium*****',
  'tienocarbine**',
  'tienopramine**',
  'tienoxolol****',
  'tifacogin*****',
  'tifemoxone****',
  'tifenamil*****',
  'tifencillin***',
  'tiflamizole***',
  'tiflorex******',
  'tifluadom*****',
  'tiflucarbine**',
  'tiformin******',
  'tifurac*******',
  'tifuvirtide***',
  'tigatuzumab***',
  'tigecycline***',
  'tigemonam*****',
  'tigestol******',
  'tigloidine****',
  'tilactase*****',
  'tilarginine***',
  'tilbroquinol**',
  'tildipirosine*',
  'tiletamine****',
  'tilidine******',
  'tiliquinol****',
  'tilisolol*****',
  'tilmacoxib****',
  'tilmicosin****',
  'tilnoprofen***',
  'tilomisole****',
  'tilorone******',
  'tilozepine****',
  'tilsuprost****',
  'tiludronate***',
  'timefurone****',
  'timegadine****',
  'timelotem*****',
  'timepidium****',
  'timiperone****',
  'timirdine*****',
  'timobesone****',
  'timofibrate***',
  'timonacic*****',
  'timoprazole***',
  'tinabinol*****',
  'tinazoline****',
  'tinisulpride**',
  'tinofedrine***',
  'tinoridine****',
  'tinzaparin****',
  'tiocarlide****',
  'tioclomarol***',
  'tioconazole***',
  'tioctilate****',
  'tiodazosin****',
  'tiodonium*****',
  'tiomergine****',
  'tiomesterone**',
  'tioperidone***',
  'tiopinac******',
  'tiopronin*****',
  'tiopropamine**',
  'tiosalan******',
  'tiospirone****',
  'tiotidine*****',
  'tiotixene*****',
  'tioxacin******',
  'tioxamast*****',
  'tioxaprofen***',
  'tioxidazole***',
  'tioxolone*****',
  'tipapkinogene*',
  'tipelukast****',
  'tipentosin****',
  'tipepidine****',
  'tipetropium***',
  'tipifarnib****',
  'tipindole*****',
  'tiplasinin****',
  'tiplimotide***',
  'tipredane*****',
  'tiprelestat***',
  'tiprenolol****',
  'tiprinast*****',
  'tipropidil****',
  'tiprostanide**',
  'tiprotimod****',
  'tiqueside*****',
  'tiquinamide***',
  'tiquizium*****',
  'tiracizine****',
  'tirapazamine**',
  'tiratricol****',
  'tirilazad*****',
  'tirofiban*****',
  'tiropramide***',
  'tisocalcitate*',
  'tisocromide***',
  'tisopurine****',
  'tisoquone*****',
  'tivanidazole**',
  'tivantinib****',
  'tivirapine****',
  'tivozanib*****',
  'tixadil*******',
  'tixanox*******',
  'tixocortol****',
  'tizabrin******',
  'tizanidine****',
  'tizolemide****',
  'tobicillin****',
  'toborinone****',
  'tobuterol*****',
  'tocainide*****',
  'tocamphyl*****',
  'toceranib*****',
  'tocilizumab***',
  'tocofenoxate**',
  'tocofersolan**',
  'tocofibrate***',
  'todralazine***',
  'tofacitinib***',
  'tofenacin*****',
  'tofetridine***',
  'tofimilast****',
  'tofisopam*****',
  'tofogliflozin*',
  'tolafentrine**',
  'tolamolol*****',
  'tolazamide****',
  'tolazoline****',
  'tolboxane*****',
  'tolcapone*****',
  'tolciclate****',
  'toldimfos*****',
  'tolevamer*****',
  'tolfamide*****',
  'tolgabide*****',
  'tolimidone****',
  'tolindate*****',
  'toliodium*****',
  'toliprolol****',
  'tolmesoxide***',
  'tolmetin******',
  'tolnaftate****',
  'tolnapersine**',
  'tolnidamine***',
  'toloconium****',
  'tolonidine****',
  'tolonium******',
  'toloxatone****',
  'tolpadol******',
  'tolpentamide**',
  'tolperisone***',
  'tolpiprazole**',
  'tolpovidone***',
  'tolpronine****',
  'tolpropamine**',
  'tolpyrramide**',
  'tolquinzole***',
  'tolrestat*****',
  'toltrazuril***',
  'tolufazepam***',
  'tolycaine*****',
  'tomelukast****',
  'tomoglumide***',
  'tomopenem*****',
  'tomoxetine****',
  'tomoxiprole***',
  'tonapofylline*',
  'tonazocine****',
  'tonzonium*****',
  'topiroxostat**',
  'topixantrone**',
  'topotecan*****',
  'toprilidine***',
  'topterone*****',
  'toquizine*****',
  'toralizumab***',
  'torapsel******',
  'torbafylline**',
  'torcetrapib***',
  'torcitabine***',
  'toremifene****',
  'torezolid*****',
  'toripristone**',
  'tosactide*****',
  'tosagestin****',
  'tosedostat****',
  'tosifen*******',
  'tositumomab***',
  'tosufloxacin**',
  'tosulur*******',
  'totrombopag***',
  'tozadenant****',
  'tozalinone****',
  'tozasertib****',
  'tozinameran***',
  'trabectedin***',
  'trabedersen***',
  'traboxopine***',
  'tracazolate***',
  'tradecamide***',
  'trafermin*****',
  'tralokinumab**',
  'tralonide*****',
  'tramazoline***',
  'tramiprosate**',
  'trandolapril**',
  'trandolaprilat*',
  'tranilast*****',
  'transcainide**',
  'transferrin***',
  'trantelinium**',
  'trapencaine***',
  'trapidil******',
  'travoprost****',
  'traxanox******',
  'trazitiline***',
  'trazium*******',
  'trazodone*****',
  'trazolopride**',
  'trebananib****',
  'trebenzomine**',
  'trecadrine****',
  'trecovirsen***',
  'trefentanil***',
  'tregalizumab**',
  'trelagliptin**',
  'trelanserin***',
  'trelnarizine**',
  'treloxinate***',
  'tremacamra****',
  'tremelimumab**',
  'trenbolone****',
  'trengestone***',
  'trenizine*****',
  'treosulfan****',
  'trepibutone***',
  'trepipam******',
  'trepirium*****',
  'treprostinil**',
  'treptilamine**',
  'trequinsin****',
  'tresperimus***',
  'trestolone****',
  'tretamine*****',
  'tretazicar****',
  'trethinium****',
  'tretoquinol***',
  'triacetin*****',
  'triafungin****',
  'triampyzine***',
  'triaziquone***',
  'triazolam*****',
  'tribendilol***',
  'tribenoside***',
  'tribromsalan**',
  'tribuzone*****',
  'triciribine***',
  'triclacetamol*',
  'triclazate****',
  'triclobisonium*',
  'triclocarban**',
  'triclodazol***',
  'triclofenol***',
  'triclofos*****',
  'triclofylline*',
  'triclonide****',
  'triclosan*****',
  'tricosactide**',
  'tricyclamol***',
  'tridihexethyl*',
  'trientine*****',
  'trifenagrel***',
  'trifezolac****',
  'triflocin*****',
  'triflubazam***',
  'triflumidate**',
  'trifluperidol*',
  'trifluridine**',
  'triflusal*****',
  'trifocon******',
  'trigevolol****',
  'triletide*****',
  'trilostane****',
  'trimazosin****',
  'trimebutine***',
  'trimecaine****',
  'trimedoxime***',
  'trimegestone**',
  'trimeperidine*',
  'trimetamide***',
  'trimetaphan***',
  'trimetazidine*',
  'trimethadione*',
  'trimethidinium*',
  'trimetozine***',
  'trimetrexate**',
  'trimexiline***',
  'trimipramine**',
  'trimoprostil**',
  'trimoxamine***',
  'trioxifene****',
  'trioxysalen***',
  'tripalmitin***',
  'tripamide*****',
  'triparanol****',
  'tripelennamine*',
  'triprolidine**',
  'tritiozine****',
  'tritoqualine**',
  'trixolane*****',
  'trizoxime*****',
  'trocimine*****',
  'troclosene****',
  'trodusquemine*',
  'trofosfamide**',
  'troglitazone**',
  'troleandomycin*',
  'trolnitrate***',
  'tromantadine**',
  'trometamol****',
  'tropabazate***',
  'tropanserin***',
  'tropantiol****',
  'tropapride****',
  'tropatepine***',
  'tropenziline**',
  'tropigline****',
  'tropirine*****',
  'troplasminogen*',
  'tropodifene***',
  'troquidazole**',
  'trospectomycin*',
  'trospium******',
  'trovafloxacin*',
  'trovirdine****',
  'troxerutin****',
  'troxipide*****',
  'troxolamide***',
  'troxonium*****',
  'troxypyrrolium*',
  'truxicurium***',
  'truxipicurium*',
  'tryparsamide**',
  'tuaminoheptane*',
  'tubocurarine**',
  'tubulozole****',
  'tucaresol*****',
  'tuclazepam****',
  'tucotuzumab***',
  'tulathromycin*',
  'tulobuterol***',
  'tulopafant****',
  'turoctocog****',
  'turofexorate**',
  'turosteride***',
  'tuvatidine****',
  'tuvirumab*****',
  'tybamate******',
  'tylosin*******',
  'tylvalosin****',
  'tyromedan*****',
  'tyrosine******',
  'tyrothricin***',
  'ubenimex******',
  'ubidecarenone*',
  'ubisindine****',
  'ublituximab***',
  'udenafil******',
  'ufenamate*****',
  'ufiprazole****',
  'ularitide*****',
  'uldazepam*****',
  'ulimorelin****',
  'ulobetasol****',
  'umespirone****',
  'umifenovir****',
  'umirolimus****',
  'unoprostone***',
  'upamostat*****',
  'upenazime*****',
  'uramustine****',
  'urapidil******',
  'uredepa*******',
  'uredofos******',
  'urefibrate****',
  'urelumab******',
  'urethane******',
  'uridine*******',
  'urofollitropin*',
  'urokinase*****',
  'urtoxazumab***',
  'usistapide****',
  'ustekinumab***',
  'utibapril*****',
  'utibaprilat***',
  'vabicaserin***',
  'vadimezan*****',
  'vadocaine*****',
  'valategrast***',
  'valconazole***',
  'valdecoxib****',
  'valdetamide***',
  'valdipromide**',
  'valine********',
  'valnemulin****',
  'valnoctamide**',
  'valofane******',
  'valopicitabine*',
  'valperinol****',
  'valproate*****',
  'valpromide****',
  'valrocemide***',
  'valspodar*****',
  'valtorcitabine*',
  'valtrate******',
  'vamicamide****',
  'vandetanib****',
  'vaneprim******',
  'vaniprevir****',
  'vanitiolide***',
  'vanoxerine****',
  'vanutide******',
  'vapaliximab***',
  'vapiprost*****',
  'vapitadine****',
  'vapreotide****',
  'vardenafil****',
  'varespladib***',
  'varfollitropin*',
  'varlitinib****',
  'vatalanib*****',
  'vatanidipine**',
  'vatelizumab***',
  'vatreptacog***',
  'vebufloxacin**',
  'vedaclidine***',
  'vedaprofen****',
  'vedolizumab***',
  'velafermin****',
  'velaglucerase*',
  'velaresol*****',
  'veliflapon****',
  'velimogene****',
  'veliparib*****',
  'velnacrine****',
  'velneperit****',
  'veltuzumab****',
  'velusetrag****',
  'vemurafenib***',
  'venritidine***',
  'vepalimomab***',
  'veradoline****',
  'veralipride***',
  'verazide******',
  'verilopam*****',
  'verlukast*****',
  'verofylline***',
  'verpasep******',
  'versetamide***',
  'verubulin*****',
  'verucerfont***',
  'vesencumab****',
  'vesnarinone***',
  'vestipitant***',
  'vetrabutine***',
  'vidarabine****',
  'vidofludimus**',
  'vidupiprant***',
  'vigabatrin****',
  'vilazodone****',
  'vildagliptin**',
  'viloxazine****',
  'viminol*******',
  'vinbarbital***',
  'vinburnine****',
  'vincamine*****',
  'vincanol******',
  'vincantril****',
  'vincofos******',
  'vinconate*****',
  'vindeburnol***',
  'vindesine*****',
  'vinepidine****',
  'vinflunine****',
  'vinformide****',
  'vinfosiltine**',
  'vinglycinate**',
  'vinleucinol***',
  'vinleurosine**',
  'vinmegallate**',
  'vinpocetine***',
  'vinpoline*****',
  'vinrosidine***',
  'vintiamol*****',
  'vintoperol****',
  'vintriptol****',
  'vinylbital****',
  'vinzolidine***',
  'viomycin******',
  'vipadenant****',
  'viprostol*****',
  'viqualine*****',
  'viquidacin****',
  'viquidil******',
  'virginiamycin*',
  'viridofulvin**',
  'viroxime******',
  'visilizumab***',
  'vismodegib****',
  'visnadine*****',
  'visnafylline**',
  'vistatolon****',
  'voclosporin***',
  'voglibose*****',
  'volasertib****',
  'volazocine****',
  'volinanserin**',
  'volociximab***',
  'vonicog*******',
  'vorapaxar*****',
  'voreloxin*****',
  'vorinostat****',
  'vorozole******',
  'vortioxetine**',
  'vosaroxin*****',
  'votumumab*****',
  'voxergolide***',
  'xaliproden****',
  'xamoterol*****',
  'xanomeline****',
  'xanthiol******',
  'xantifibrate**',
  'xantinol******',
  'xantocillin***',
  'xantofyl******',
  'xemilofiban***',
  'xenalipin*****',
  'xenbucin******',
  'xenipentone***',
  'xenon*********',
  'xenthiorate***',
  'xenygloxal****',
  'xenysalate****',
  'xenytropium***',
  'xibenolol*****',
  'xibornol******',
  'xilobam*******',
  'ximelagatran**',
  'ximoprofen****',
  'xinidamine****',
  'xinomiline****',
  'xipamide******',
  'xipranolol****',
  'xorphanol*****',
  'xylamidine****',
  'xylazine******',
  'xylocoumarol**',
  'xyloxemine****',
  'clivatuzumab**',
  'ibritumomab***',
  'zabicipril****',
  'zabiciprilat**',
  'zabofloxacin**',
  'zacopride*****',
  'zafirlukast***',
  'zafuleptine***',
  'zalcitabine***',
  'zaldaride*****',
  'zaleplon******',
  'zalospirone***',
  'zalutumumab***',
  'zaltidine*****',
  'zaltoprofen***',
  'zamifenacin***',
  'zanamivir*****',
  'zankiren******',
  'zanolimumab***',
  'zanoterone****',
  'zapizolam*****',
  'zaprinast*****',
  'zardaverine***',
  'zatebradine***',
  'zatosetron****',
  'zaurategrast**',
  'zenarestat****',
  'zeniplatin****',
  'zepastine*****',
  'zeranol*******',
  'zetidoline****',
  'zibotentan****',
  'ziconotide****',
  'zicronapine***',
  'zidapamide****',
  'zidometacin***',
  'zifrosilone***',
  'zilantel******',
  'zilascorb*****',
  'zileuton******',
  'zilpaterol****',
  'zimeldine*****',
  'zimidoben*****',
  'zinapitant****',
  'zindotrine****',
  'zindoxifene***',
  'zinoconazole**',
  'zinostatin****',
  'zinterol******',
  'zinviroxime***',
  'zipeprol******',
  'ziprasidone***',
  'ziralimumab***',
  'zocainone*****',
  'zofenopril****',
  'zofenoprilat**',
  'zoficonazole**',
  'zolamine******',
  'zolasartan****',
  'zolazepam*****',
  'zolenzepine***',
  'zoleprodolol**',
  'zolertine*****',
  'zolimidine****',
  'zolimomab*****',
  'zoliprofen****',
  'zolmitriptan**',
  'zoloperone****',
  'zolpidem******',
  'zomebazam*****',
  'zomepirac*****',
  'zonampanel****',
  'zoniclezole***',
  'zoniporide****',
  'zonisamide****',
  'zopiclone*****',
  'zopolrestat***',
  'zorubicin*****',
  'zosuquidar****',
  'zotarolimus***',
  'zotepine******',
  'zoxazolamine**',
  'zucapsaicin***',
  'zuclomifene***',
  'zuclopenthixol*',
  'zylofuramine**',
  'adoprazine****',
  'albiglutide***',
  'albinterferon*',
  'alferminogene*',
  'alipogene*****',
  'almorexant****',
  'amifampridine*',
  'amolimogene***',
  'amsilarotene**',
  'axitinib******',
  'azilsartan****',
  'beperminogene*',
  'beroctocog****',
  'berubicin*****',
  'besifloxacin**',
  'betrixaban****',
  'bucelipase****',
  'carisbamate***',
  'ceftaroline***',
  'conestat******',
  'contusugene***',
  'danusertib****',
  'daporinad*****',
  'elesclomol****',
  'giripladib****',
  'intiquinatine*',
  'lancovutide***',
  'lapaquistat***',
  'larazotide****',
  'laromustine***',
  'laropiprant***',
  'larotaxel*****',
  'lensiprazine**',
  'levamlodipine*',
  'levonebivolol*',
  'lificiguat****',
  'linopristin***',
  'litenimod*****',
  'lixisenatide**',
  'lobeglitazone*',
  'lodenafil*****',
  'lonaprisan****',
  'lorcaserin****',
  'macitentan****',
  'managlinat****',
  'masilukast****',
  'masitinib*****',
  'mavacoxib*****',
  'melogliptin***',
  'metenkefalin**',
  'mimopezil*****',
  'mirodenafil***',
  'monepantel****',
  'nelivaptan****',
  'nemonoxacin***',
  'nepidermin****',
  'niraxostat****',
  'obinepitide***',
  'obinutuzumab**',
  'oglemilast****',
  'olaparib******',
  'olesoxime*****',
  'ombrabulin****',
  'ozenoxacin****',
  'padeliporfin**',
  'panobinostat**',
  'paquinimod****',
  'parogrelil****',
  'peretinoin****',
  'posaraprost***',
  'quarfloxin****',
  'radiprodil****',
  'remogliflozin*',
  'rilapladib****',
  'ropidoxuridine',
  'rosonabant****',
  'rusalatide****',
  'sodelglitazar*',
  'teneligliptin*',
  'tigapotide****',
  'tridecactide**',
  'troplasminogen',
  'vernakalant***',
  'vicriviroc****',
  'volinaserin***',
  'votucalis*****',
  'abatacept*****',
  'acolbifene****',
  'acotiamide****',
  'alagebrium****',
  'albaconazole**',
  'alefacept*****',
  'alfimeprase***',
  'alglucosidase*',
  'aliskiren*****',
  'amatuximab****',
  'amdoxovir*****',
  'amotosalen****',
  'amustaline****',
  'ancriviroc****',
  'apaziquone****',
  'aplindore*****',
  'apolizumab****',
  'aprinocarsen**',
  'ardenermin****',
  'armodafinil***',
  'asoprisnil****',
  'atilmotin*****',
  'atomoxetine***',
  'avanafil******',
  'batabulin*****',
  'bazedoxifene**',
  'becatecarin***',
  'befetupitant**',
  'belimumab*****',
  'belotecan*****',
  'bemotrizinol**',
  'bifeprunox****',
  'bimatoprost***',
  'binodenoson***',
  'bisdisulizole*',
  'bisoctrizole**',
  'brentuximab***',
  'canertinib****',
  'canfosfamide**',
  'cangrelor*****',
  'cefovecin*****',
  'cethromycin***',
  'cetilistat****',
  'cilengitide***',
  'ciluprevir****',
  'cinacalcet****',
  'cintredekin***',
  'cipralisant***',
  'clofarabine***',
  'dabuzalgron***',
  'dalbavancin***',
  'dapiglifozin**',
  'deferitrin****',
  'deligoparin***',
  'delmitide*****',
  'delucemine****',
  'denufosol*****',
  'depelestat****',
  'desmoteplase**',
  'desvenlafaxine',
  'dexpramipexole',
  'dextofisopam**',
  'dibotermin****',
  'dirlotapide***',
  'disufenton****',
  'doramapimod***',
  'doripenem*****',
  'drozitumab****',
  'dulaglutide***',
  'ebanicline****',
  'ecalcidene****',
  'ecopladib*****',
  'eculizumab****',
  'edifoligide***',
  'edonentan*****',
  'edotecarin****',
  'edotreotide***',
  'edratide******',
  'efaproxiral***',
  'eglumetad*****',
  'eliglustat****',
  'elotuzumab****',
  'elvucitabine**',
  'elzasonan*****',
  'enfuvirtide***',
  'ensituximab***',
  'enzastaurin***',
  'eplivanserin**',
  'eritoran******',
  'ertapenem*****',
  'ertiprotafib**',
  'esoxybutynin**',
  'eszopiclone***',
  'etalocib******',
  'etilevodopa***',
  'etiprednol****',
  'etravirine****',
  'exenatide*****',
  'fandosentan***',
  'farampator****',
  'fesoterodine**',
  'fidexaban*****',
  'firocoxib*****',
  'flindokalner**',
  'florbetapir***',
  'fondaparinux**',
  'fosveset******',
  'gadofosveset**',
  'galiximab*****',
  'galsulfase****',
  'ganitumab*****',
  'garnocestim***',
  'gataparsen****',
  'gemcabene*****',
  'iboctadekin***',
  'ibrolipim*****',
  'icodextin*****',
  'idraparinux***',
  'idronoxil*****',
  'idursulfase***',
  'indacaterol***',
  'indibulin*****',
  'indiplon******',
  'indisulam*****',
  'iniparib******',
  'iosimenol*****',
  'iseganan******',
  'ismomultin****',
  'ispinesib*****',
  'ivacaftor*****',
  'ixabepilone***',
  'labradimil****',
  'lanimostim****',
  'lapatinib*****',
  'laquinimod****',
  'lestaurtinib**',
  'levosalbutamol',
  'levotofisopam*',
  'lidorestat****',
  'liprotamase***',
  'liraglutide***',
  'lonafarnib****',
  'lubazodone****',
  'lucinactant***',
  'tolvaptan*****',
  'dimenhydrinate',
  'penicillin****',
  'ponatinib*****',
  'peglocticase**',
  'lemborexant***',
  'omidenepag****',
  'tepotinib*****',
]
