import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { CakeIcon, AcademicCapIcon } from '@heroicons/react/outline'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the drug name in 8 tries. After each guess, the tile colours will
        change to let you know how close your guess was to the drug name.
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <Cell value="A" />
        <Cell value="S" />
        <Cell value="P" />
        <Cell value="I" />
        <Cell value="R" />
        <Cell value="I" />
        <Cell value="N" />
        <Cell value="*" />
        <Cell value="*" />
        <Cell value="*" />
        <Cell value="*" />
        <Cell value="*" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Medicine names come in many different lengths. Use the asterisk to
        indicate the absence of a letter.
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="Y" />
        <Cell value="R" />
        <Cell value="A" />
        <Cell value="Z" />
        <Cell value="I" />
        <Cell value="N" />
        <Cell value="A" />
        <Cell value="M" status="absent" />
        <Cell value="I" />
        <Cell value="D" />
        <Cell value="E" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter M does not appear in the drug name.
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <Cell value="B" />
        <Cell value="E" />
        <Cell value="N" />
        <Cell value="Z" status="present" />
        <Cell value="A" />
        <Cell value="T" />
        <Cell value="R" />
        <Cell value="O" />
        <Cell value="P" />
        <Cell value="I" />
        <Cell value="N" />
        <Cell value="E" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter Z is present in the drug name but is misplaced.
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <Cell value="G" status="correct" />
        <Cell value="R" />
        <Cell value="I" />
        <Cell value="S" />
        <Cell value="E" />
        <Cell value="O" />
        <Cell value="F" />
        <Cell value="U" />
        <Cell value="L" />
        <Cell value="V" />
        <Cell value="I" />
        <Cell value="N" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter G appears in the drug name and is positioned correctly.
      </p>
      <p>
        <div className="flex justify-center mb-1 mt-4 text-sm text-gray-500 dark:text-gray-300">
          <AcademicCapIcon className="flex justify-center h-6 w-6 ml-0 mr-1 text-black dark:text-white" />
          {'/'}
          <CakeIcon className="flex justify-center h-6 w-6 ml-1 mr-2 text-black dark:text-white" />
          {'ON/OFF must use correctly guessed letters.'}
        </div>
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        If you need a clue click the{' '}
        <button
          type="button"
          className="mx-auto mt-1.5 px-1.5 py-1
          border border-transparent text-xs font-medium rounded text-indigo-700
          bg-indigo-100 focus:outline-none "
          onClick={() => {}}
        >
          Hint
        </button>{' '}
        button at the bottom to see the drug class and examples of indications.
      </p>
    </BaseModal>
  )
}
