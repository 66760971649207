import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About Drugdle" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Drugdle was created by{' '}
        <a
          href="https://blog.nus.edu.sg/phcdgs/#.YgX-w5YRWUk"
          className="underline font-bold"
        >
          PharmaNUS
        </a>{' '}
        to help medical and health professionals and students actively recall
        and learn to correctly spell the international nonproprietary names
        (INNs) of commonly prescribed drugs. Drug names are taken from the WHO
        Model List of Essential Medicines and the Singapore Ministry of Health
        list of subsidised drugs.{' '}
      </p>
    </BaseModal>
  )
}
