import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'
import { GAME_TITLE } from '../constants/strings'
import { GameStats } from './localStorage'

export const shareStatus = (
  gameStats: GameStats,
  guesses: string[],
  lost: boolean,
  isHardMode: boolean
) => {
  const stats = { ...gameStats }
  // Increment solutionIndex by 596 for game epoch 2 starting 20 Aug 2023
  navigator.clipboard.writeText(
    `${'#'}${GAME_TITLE} ${solutionIndex + 596} ${
      lost ? 'X' : guesses.length
    }/8${isHardMode ? '*' : ''} ${stats.hintStatus ? 'Hint' : ''}\n` +
      generateEmojiGrid(guesses)
  )
}
export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      return guess
        .split('')
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟦'
            case 'present':
              return '🟧'
            default:
              return '⬜'
          }
        })
        .join('')
    })
    .join('\n')
}
