export const WORDS = [
'tramadol******',
'clotrimazole**',
'fluorescein***',
'clarithromycin',
'simvastatin***',
'bicalutamide**',
'dapagliflozin*',
'brigatinib****',
'furosemide****',
'trametinib****',
'salicylate****',
'terbinafine***',
'ibuprofen*****',
'avibactam*****',
'permethrin****',
'phenylephrine*',
'homatropine***',
'clozapine*****',
'aminophylline*',
'flumazenil****',
'pamidronate***',
'hydromorphone*',
'velpatasvir***',
'oseltamivir***',
'eflornithine**',
'daclatasvir***',
'kanamycin*****',
'rifapentine***',
'methyldopa****',
'nicotinamide**',
'calamine******',
'sulfadoxine***',
'terbutaline***',
'imatinib******',
'tolterodine***',
'testosterone**',
'rivaroxaban***',
'bedaquiline***',
'salmeterol****',
'nicotine******',
'dabigatran****',
'gemfibrozil***',
'etoricoxib****',
'tamsulosin****',
'paracetamol***',
'ritonavir*****',
'retinol*******',
'ethambutol****',
'betahistine***',
'piperaquine***',
'metoprolol****',
'alfuzosin*****',
'celecoxib*****',
'neostigmine***',
'azathioprine**',
'clomifene*****',
'rasburicase***',
'edoxaban******',
'tropisetron***',
'fluphenazine**',
'chlorhexidine*',
'betamethasone*',
'cabergoline***',
'tobramycin****',
'cyclizine*****',
'gemcitabine***',
'paroxetine****',
'probenecid****',
'nadroparin****',
'theophylline**',
'leuprorelin***',
'bismuth*******',
'lumefantrine**',
'linagliptin***',
'pentamidine***',
'risedronate***',
'isoniazid*****',
'bisoprolol****',
'ropinirole****',
'ethanol*******',
'erlotinib*****',
'thiamine******',
'ciclosporin***',
'letrozole*****',
'venlafaxine***',
'umeclidinium**',
'artesunate****',
'pyrantel******',
'sofosbuvir****',
'acetazolamide*',
'atracurium****',
'liothyronine**',
'mitomycin*****',
'ixekizumab****',
'methotrexate**',
'iodine********',
'cefazolin*****',
'citalopram****',
'flucytosine***',
'ondansetron***',
'amikacin******',
'ergometrine***',
'cloxacillin***',
'chlorpromazine',
'ledipasvir****',
'ethosuximide**',
'febuxostat****',
'mitoxantrone**',
'amoxicillin***',
'carvedilol****',
'fluconazole***',
'nitrazepam****',
'heparin*******',
'suramin*******',
'piperacillin**',
'ulipristal****',
'irinotecan****',
'dobutamine****',
'sevelamer*****',
'caspofungin***',
'candesartan***',
'estradiol*****',
'ciclesonide***',
'sulfadiazine**',
'itraconazole**',
'naloxone******',
'granisetron***',
'piroxicam*****',
'omeprazole****',
'dacomitinib***',
'docetaxel*****',
'fentanyl******',
'colistin******',
'chlorambucil**',
'quetiapine****',
'albendazole***',
'benserazide***',
'rilpivirine***',
'emtricitabine*',
'alteplase*****',
'miltefosine***',
'raltegravir***',
'prednisolone**',
'donepezil*****',
'cefotaxime****',
'bupropion*****',
'epoprostenol**',
'pegaspargase**',
'nivolumab*****',
'lorazepam*****',
'rifampicin****',
'golimumab*****',
'cyproheptadine',
'deferasirox***',
'valsartan*****',
'metoclopramide',
'propafenone***',
'vasopressin***',
'norepinephrine',
'denosumab*****',
'pemetrexed****',
'halothane*****',
'acitretin*****',
'daunorubicin**',
'diphenoxylate*',
'mometasone****',
'indapamide****',
'ceritinib*****',
'octreotide****',
'levocarnitine*',
'malathion*****',
'entecavir*****',
'hydroxyzine***',
'levodopa******',
'vaborbactam***',
'cetrimide*****',
'amidotrizoate*',
'levothyroxine*',
'mirtazapine***',
'mesna*********',
'cefuroxime****',
'abiraterone***',
'alfacalcidol**',
'gliclazide****',
'ceftriaxone***',
'gentamicin****',
'lomustine*****',
'ambrisentan***',
'benzatropine**',
'sorafenib*****',
'abacavir******',
'pazopanib*****',
'trastuzumab***',
'isosorbide****',
'aciclovir*****',
'vilanterol****',
'captopril*****',
'famotidine****',
'ketoprofen****',
'warfarin******',
'rituximab*****',
'docusate******',
'dequalinium***',
'varenicline***',
'senna*********',
'delamanid*****',
'sotalol*******',
'dolutegravir**',
'antazoline****',
'penicillamine*',
'pancuronium***',
'megestrol*****',
'anidulafungin*',
'biperiden*****',
'cytarabine****',
'mebendazole***',
'proxymetacaine',
'clofazimine***',
'spectinomycin*',
'amiloride*****',
'chloroxylenol*',
'glipizide*****',
'cilastatin****',
'fexinidazole**',
'tolbutamide***',
'fluoxetine****',
'finasteride***',
'methadone*****',
'fluorouracil**',
'flecainide****',
'mifepristone**',
'etoposide*****',
'pyridostigmine',
'salbutamol****',
'anastrozole***',
'ribavirin*****',
'amiodarone****',
'morphine******',
'lithium*******',
'aclidinium****',
'netupitant****',
'everolimus****',
'paromomycin***',
'levofloxacin**',
'erythromycin**',
'sumatriptan***',
'pibrentasvir**',
'micafungin****',
'oxygen********',
'canagliflozin*',
'thiamazole****',
'colestyramine*',
'mercaptopurine',
'exemestane****',
'calcium*******',
'codeine*******',
'adalimumab****',
'clomipramine**',
'fingolimod****',
'enzalutamide**',
'cefixime******',
'tetryzoline***',
'glycine*******',
'meropenem*****',
'xylometazoline',
'regorafenib***',
'prazosin******',
'pralidoxime***',
'vancomycin****',
'valaciclovir**',
'ombitasvir****',
'paliperidone**',
'tinidazole****',
'atazanavir****',
'triamcinolone*',
'riboflavin****',
'tropicamide***',
'ivermectin****',
'mebeverine****',
'fluvoxamine***',
'moclobemide***',
'dasatinib*****',
'latanoprost***',
'blinatumomab**',
'olanzapine****',
'bumetanide****',
'oxybutynin****',
'topiramate****',
'hydrocortisone',
'clonazepam****',
'chloroquine***',
'vecuronium****',
'ifosfamide****',
'tacalcitol****',
'carbetocin****',
'somatostatin**',
'sacubitril****',
'beclometasone*',
'dacarbazine***',
'dapsone*******',
'solifenacin***',
'levetiracetam*',
'calcipotriol**',
'nandrolone****',
'imipenem******',
'pravastatin***',
'ofloxacin*****',
'deferiprone***',
'praziquantel**',
'dutasteride***',
'dantrolene****',
'betaxolol*****',
'siponimod*****',
'rifabutin*****',
'nifedipine****',
'degarelix*****',
'clindamycin***',
'fluticasone***',
'carbamazepine*',
'busulfan******',
'eltrombopag***',
'mupirocin*****',
'fosfomycin****',
'bendamustine**',
'dolasetron****',
'stiripentol***',
'acetylcysteine',
'ketamine******',
'metronidazole*',
'valganciclovir',
'guaifenesin***',
'artemether****',
'diloxanide****',
'mannitol******',
'vinorelbine***',
'doxorubicin***',
'benznidazole**',
'zidovudine****',
'glibenclamide*',
'tetracaine****',
'procainamide**',
'ephedrine*****',
'chlorothiazide',
'tiotropium****',
'colecalciferol',
'oxymetazoline*',
'lamotrigine***',
'progesterone**',
'amlodipine****',
'somatropin****',
'apixaban******',
'natamycin*****',
'glycopyrronium',
'nifurtimox****',
'ciprofloxacin*',
'formoterol****',
'pramipexole***',
'terazosin*****',
'haloperidol***',
'paclitaxel****',
'ranibizumab***',
'nitrofurantoin',
'amodiaquine***',
'dimercaprol***',
'dopamine******',
'fenofibrate***',
'fluocinolone**',
'streptokinase*',
'nystatin******',
'suxamethonium*',
'norethisterone',
'buprenorphine*',
'ibrutinib*****',
'carbidopa*****',
'enoxaparin****',
'telmisartan***',
'dydrogesterone',
'urea**********',
'nilotinib*****',
'tazobactam****',
'bisacodyl*****',
'doxycycline***',
'plazomicin****',
'calcitriol****',
'temozolomide**',
'ticagrelor****',
'misoprostol***',
'chlortalidone*',
'filgrastim****',
'dactinomycin**',
'melarsoprol***',
'ethionamide***',
'oxytocin******',
'lenalidomide**',
'sirolimus*****',
'loratadine****',
'tioguanine****',
'dipyridamole**',
'goserelin*****',
'amitriptyline*',
'phenytoin*****',
'tamoxifen*****',
'tacrolimus****',
'baricitinib***',
'lovastatin****',
'fexofenadine**',
'quinine*******',
'linezolid*****',
'timolol*******',
'spironolactone',
'mesalazine****',
'phytomenadione',
'pembrolizumab*',
'simeticone****',
'pyrazinamide**',
'thalidomide***',
'glucagon******',
'bleomycin*****',
'pyronaridine**',
'kaolin********',
'propranolol***',
'loperamide****',
'dexamethasone*',
'thiopental****',
'brimonidine***',
'bortezomib****',
'palonosetron**',
'ergocalciferol',
'dalteparin****',
'griseofulvin**',
'bromocriptine*',
'cycloserine***',
'diazepam******',
'flupentixol***',
'esomeprazole**',
'sulfasalazine*',
'aspirin*******',
'procarbazine**',
'capecitabine**',
'deferoxamine**',
'pyridoxine****',
'droperidol****',
'etanercept****',
'cyclopentolate',
'triamterene***',
'azithromycin**',
'procaine******',
'digoxin*******',
'moxifloxacin**',
'dabrafenib****',
'hypromellose**',
'ampicillin****',
'methoxsalen***',
'empagliflozin*',
'triptorelin***',
'ganciclovir***',
'glycerol******',
'carboplatin***',
'propofol******',
'hydralazine***',
'fludarabine***',
'bevacizumab***',
'gabapentin****',
'omalizumab****',
'rifaximin*****',
'primaquine****',
'posaconazole**',
'afatinib******',
'ranitidine****',
'aprepitant****',
'torasemide****',
'bupivacaine***',
'certolizumab**',
'paritaprevir**',
'oxycodone*****',
'cisplatin*****',
'ipratropium***',
'sertraline****',
'nevirapine****',
'betaine*******',
'dasabuvir*****',
'atenolol******',
'diosmin*******',
'insulin*******',
'niclosamide***',
'neomycin******',
'levamisole****',
'cyproterone***',
'atorvastatin**',
'verapamil*****',
'ezetimibe*****',
'methionine****',
'ceftazidime***',
'budesonide****',
'diosmectite***',
'phenobarbital*',
'isoflurane****',
'promethazine**',
'rivastigmine**',
'midazolam*****',
'voriconazole**',
'lactulose*****',
'enalapril*****',
'hesperidin****',
'diclofenac****',
'bethanechol***',
'losartan******',
'infliximab****',
'trimethoprim**',
'leflunomide***',
'risperidone***',
'flunisolide***',
'gefitinib*****',
'pyrimethamine*',
'ticlopidine***',
'asparaginase**',
'streptomycin**',
'clopidogrel***',
'hyoscine******',
'physostigmine*',
'mefloquine****',
'naphazoline***',
'cefiderocol***',
'cetirizine****',
'tretinoin*****',
'isoprenaline**',
'escitalopram**',
'lopinavir*****',
'metformin*****',
'stibogluconate',
'vinblastine***',
'netilmicin****',
'fluvastatin***',
'carbachol*****',
'vincristine***',
'carmustine****',
'proguanil*****',
'lamivudine****',
'darunavir*****',
'miconazole****',
'carbimazole***',
'glutaral******',
'lisinopril****',
'levonorgestrel',
'cimetidine****',
'colchicine****',
'oxaliplatin***',
'prasugrel*****',
'diltiazem*****',
'buclizine*****',
'pethidine*****',
'tenofovir*****',
'ivabradine****',
'efavirenz*****',
'pilocarpine***',
'prednisone****',
'allopurinol***',
'lidocaine*****',
'atropine******',
'iohexol*******',
'glecaprevir***',
'eptifibatide**',
'levocetirizine',
'cefalexin*****',
'melphalan*****',
'desmopressin**',
'epinephrine***'
]
