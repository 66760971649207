import { BaseModal } from './BaseModal'
import { hint } from '../../lib/words'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const HintModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Hint" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">{hint}</p>
    </BaseModal>
  )
}
